/** @format */

import React from "react";
// @ts-ignore
import { Helmet } from "react-helmet";
import { createUseStyles } from "react-jss";
import { ThemeType } from "../styles/theme";
import { Title, Button, ButtonType, Footer, Tab } from "../components";
import { getGifUrl } from "../utils";

import section__header_bg from "../assets/service-img@2x.png";

import clsx from "clsx";
import { useCommonStyles } from "../styles/common";
import { FaqSection, NoticeSection, Header } from "../components/";

const useStyles = createUseStyles((theme: ThemeType) => ({
  section__header: {
    paddingTop: 80,
    background: theme.primaryBg,
    "& > div": {
      paddingTop: 98,
      height: 300,
      justifyContent: "space-between",
      background: `url(${getGifUrl(
        section__header_bg
      )}) right bottom no-repeat`,
      backgroundSize: "311px 300px",
    },
  },

  section__body: {
    width: 940,
    minHeight: 554,
    margin: "0 auto",
    padding: "90px 0px 180px",
    "& > div": {},
  },

  section__contact: {
    padding: "130px 0px",
    background: theme.primarySol,
    "& > div": {},
  },
}));

const MainPage: React.FC = () => {
  const { section__header, section__body, section__contact } = useStyles();

  const {
    wrap,
    container,

    flex,
    flexColumn,
    white,
  } = useCommonStyles();

  return (
    <>
      <Helmet>
        <title>쯩 - 인증이 필요할 때, 쯩</title>
      </Helmet>
      <Header isBackgroundShown={false} />
      <div className={wrap}>
        <div className={clsx(section__header, wrap)}>
          <div className={clsx(container, flex)}>
            <div>
              <Title
                text={"고객센터"}
                color="white"
                style={{
                  fontSize: "5.0rem",
                  lineHeight: "6.0rem",
                  fontWeight: 500,
                }}
              />
              <p className={white}>
                쯩 서비스 이용 중 궁금한 사항이 있으신가요?
              </p>
            </div>
          </div>
        </div>

        <div className={clsx(section__body, wrap)}>
          <Tab
            menu={["FAQ", "공지사항"]}
            components={[<FaqSection />, <NoticeSection />]}
          />
        </div>

        <div className={clsx(section__contact, wrap)}>
          <div className={clsx(container, flexColumn)}>
            <Title
              text={`더 궁금하신 점이 있으신가요?\n쯩 서비스팀이 빠르게 답변해드립니다`}
              color="white"
              style={{
                textAlign: "center",
              }}
            />
            <Button
              text={"1:1 문의하기"}
              buttonType={ButtonType.white}
              onClick={() => {
                window.open("https://app.zzeung.id/app/webn/v4/#/contact");
              }}
              style={{ margin: "34px auto 0px" }}
            />
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default MainPage;
