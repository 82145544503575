import React, { Fragment, CSSProperties } from "react";
import { createUseStyles } from "react-jss";
import { ThemeType } from "../styles/theme";

interface Props {
  text: string;
  color: "white" | "black";
  style?: CSSProperties;
}

const useStyles = createUseStyles((theme: ThemeType) => ({
  wrap: (props: Props) => ({
    color: props.color === "white" ? theme.mono100 : theme.mono0,
    fontSize: "4.6rem",
    lineHeight: "6.6rem",
    fontWeight: theme.regular,
    marginBottom: 16,
    letterSpacing: "-0.08rem",
  }),
}));

const Title: React.SFC<Props> = (props) => {
  const classes = useStyles(props);
  const { text, style = {} } = props;
  return (
    <h2 style={style} className={classes.wrap}>
      {text.split("\n").map((item: string, key: number) => {
        return (
          <Fragment key={key}>
            {item}
            <br />
          </Fragment>
        );
      })}
    </h2>
  );
};

export default Title;
