import { useHistory } from "react-router-dom";

// If the domain is v1, ROUTE uses "/", "service-center", "vaccine-guide";
// If the domain is local & v1, ROUTE uses "/", "service-center", "vaccine-guide";

// If the domain is v2 & local, ROUTE uses "/", "service-center", "vaccine-guide";
// If the domain is v2 & tbed, ROUTE uses "/", "service-center", "vaccine-guide";
// If the domain is v2 & live, ROUTE uses "/", "service-center", "vaccine-guide";



export const useGlobalRouter = () => {
  const tbed = process.env.REACT_APP_ENV === "tbed";
  const history = useHistory();
  const _vaccine = process.env.REACT_ENV_TYPE === 'vax';
  const _version2 = process.env.REACT_APP_VERSION === "v2";
  const _checkLocal = window.location.href.includes("localhost");

  const goTo = (key: "home" | "serviceCenter" | "vaccineGuide") => {
    if (_checkLocal) {
      if (!_version2) {
        const _route = {
          home: "/",
          serviceCenter: "/service-center",
          vaccineGuide: "/vaccine"
        }

        return history.push(_route[key]);
      }

      if (_version2) {
        const _route = {
          home: "/",
          serviceCenter: "/service-center",
          vaccineGuide: _vaccine ? "/" : "/vaccine"
        }

        return history.push(_route[key]);
      }
    }

    if (!_checkLocal) {
      if (tbed) {
        const _route = {
          home: "https://resource-test.zzeung.id/credentials/brand/#/",
          serviceCenter: "https://resource-test.zzeung.id/credentials/brand/#/service-center",
          vaccineGuide: "https://resource-test.zzeung.id/credentials/brand/vaccine"
        }

        return window.location.href = _route[key];
      }
      if (!tbed) {
        const _route = {
          home: "https://zzeung.id/#/",
          serviceCenter: "https://zzeung.id/#/service-center",
          vaccineGuide: "https://zzeung.id/vaccine/index.html"
        }

        return window.location.href = _route[key];
      }
    }
  }

  return {
    goTo
  }
}

