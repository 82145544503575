import React, { useRef, RefObject } from "react";
import { createUseStyles } from "react-jss";
import clsx from "clsx";
import { ThemeType } from "../styles/theme";
import {
  Header,
  Footer,
  SwipeSlider
} from "../components";
import { useCommonStyles } from "../styles/common";
import { getGifUrl } from "../utils";

import band from "../assets/share/share-band-def@2x.png";
import face from "../assets/share/share-face-def@2x.png";
import kakao from "../assets/share/share-kako-def@2x.png";
import kstory from "../assets/share/share-kstory-def@2x.png";
import line from "../assets/share/share-line-def@2x.png";
import twitter from "../assets/share/share-twitter-def@2x.png";
import link from "../assets/share/share-link-def@2x.png";
import band_nor from "../assets/share/share-band-nor@2x.png";
import face_nor from "../assets/share/share-face-nor@2x.png";
import kakao_nor from "../assets/share/share-kako-nor@2x.png";
import kstory_nor from "../assets/share/share-kstory-nor@2x.png";
import line_nor from "../assets/share/share-line-nor@2x.png";
import twitter_nor from "../assets/share/share-twitter-nor@2x.png";
import link_nor from "../assets/share/share-link-nor@2x.png";

// import bgWave from "../assets/bg-wave@2x.png";
import bgWave from "../assets/bg-wave2@2x.png";
// import kio from "../assets/web-kio2@2x.png";
import kio from "../assets/web-kio3@2x.png";
import ply from "../assets/icon-play@2x.png";
import phone__1 from "../assets/img-1@2x.png";
import item__1 from "../assets/item__1@2x.png";
import imgRound01 from "../assets/img-round-01@2x.png";
import imgRound02 from "../assets/img-round-02@2x.png";
import imgRound04 from "../assets/img-round-04@2x.png";

import imgRound03 from "../assets/gif-round-01.gif";

import imgIllust from "../assets/imgIllust@2x.png";

import imgq_1_1 from "../assets/slides/quarantine/img-1-1@2x.png";
import imgq_1_2 from "../assets/slides/quarantine/img-1-2@2x.png";
import imgq_1_3 from "../assets/slides/quarantine/img-1-3@2x.png";
import imgq_2_1 from "../assets/slides/quarantine/img-2-1@2x.png";
import imgq_2_2 from "../assets/slides/quarantine/img-2-2@2x.png";
import imgq_2_3 from "../assets/slides/quarantine/img-2-3@2x.png";

const imgArr1 = [
  imgq_1_1,
  imgq_1_2,
  imgq_1_3,
];
const txtArr1 = [
  "홈 화면에서 나의 방역패스 영역을 선택하여 서비스 이용에 동의해주세요.",
  "비밀번호 입력 또는 생체 인증을 하시면 방역패스 정보를 실시간으로 확인할 수 있어요.",
  "1번을 진행하셨었다면 언제든 ‘실시간 확인’ 버튼으로 나의 방역패스 정보를 실시간으로 확인하실 수 있어요.",
];
const subTxtArr1 = [
  "",
  "",
  "",
];

const imgArr2 = [
  imgq_2_1,
  imgq_2_2,
  imgq_2_3,
];
const txtArr2 = [
  "방역패스 서비스에 동의하셨다면 QR 메뉴에서 제주안심코드 QR을 스캔해 주세요.",
  "QR 체크인을 완료하면서 백신 접종 확인도 같이 할 수 있어요.",
  "체크인이 아니어도 방역패스 확인이 필요하실 때는 쯩의 홈 화면에서 나의 방역패스 ‘실시간 확인’ 버튼을 눌러보세요!"
];
const subTxtArr2 = [
  "",
  "",
  "",
];

declare global {
  interface Window {
    Kakao: any;
    twttr: any;
  }
}

const tbed = process.env.REACT_APP_ENV === "tbed";
const _domain = tbed ? "https://resource-test.zzeung.id/credentials/brand/vaccine" : "https://zzeung.id/vaccine"
const _openGraph = `${_domain}/open-graph-169-3.png`;
const _url = tbed ? `${_domain}` : `${_domain}/index.html`;

const VaccineGuidePage: React.FC = () => {

  const {
    container,
    flex,
    flexColumn,

    phone,
    // gray,
  } = useCommonStyles();
  const { Kakao, twttr } = window;
  const classes = useStyles();
  const inputRef = useRef<HTMLInputElement>(null);
  const liRef = useRef<HTMLLIElement>(null);

  const copyToClipboard = (val: string) => {
    const element = document.createElement("textarea");
    element.value = val;
    element.setAttribute("readonly", "");
    element.style.position = "absolute";
    element.style.left = "-9999px";
    document.body.appendChild(element);
    element.select();
    var returnValue = document.execCommand("copy");
    document.body.removeChild(element);
    if (!returnValue) {
      throw new Error("copied nothing");
    }
  }

  const handleCopiedDialog = () => {
    const divInEl = document.createElement("div");
    const divEl = document.createElement("div");
    if (liRef.current) {
      divInEl.style.position = "absolute";
      divInEl.style.bottom = "-8px"
      divInEl.style.left = "5px";
      divInEl.style.borderTop = "10px solid #EBEBEB";
      divInEl.style.borderLeft = "0px solid transparent";
      divInEl.style.borderRight = "10px solid transparent";
      divInEl.style.borderBottom = "0px solid transparent";

      divEl.innerText = "URL이 복사되었습니다.";
      divEl.style.position = "absolute";
      divEl.style.display = "block";
      divEl.style.width = "135px";
      divEl.style.top = "-40px";
      divEl.style.fontSize = "12px";
      divEl.style.fontWeight = "600";
      divEl.style.color = "rgb(34, 34, 34)";
      divEl.style.left = "30px";
      divEl.style.padding = "5px 10px";
      divEl.style.backgroundColor = "#EBEBEB";
      divEl.style.borderRadius = "10px";

      divEl.appendChild(divInEl);
      liRef.current.appendChild(divEl);
    }

    setTimeout(() => {
      divEl.style.display = "none";
    }, 2000);
  }

  return (
    <>
      <Header isBackgroundShown={true} borderBottom={true} hasPath={false} />
      <div className={classes.wrap}>
        <div className={classes.header}>
          <div className="intro__subtxt">
            안전하고 편리한 방역 생활을 위해
            </div>
          <div className="intro__maintxt">
            ‘쯩’으로 방역패스를 쉽게 <br />
          실시간 확인해 보세요
          </div>
          <div className="share__link">
            <ul>
              <li
                id="kakaolink"
                onClick={async () => {
                  try {
                    await Kakao.Link.sendScrap({
                      requestUrl: _url,
                      templateId: 66577,
                      installTalk: true,
                      templateArgs: {
                        name: "쯩으로 백신 접종 정보 발급하세요.",
                        url: _url
                      }
                    });
                  } catch (e) {
                    console.error(e);
                  }
                }}
              />
              <li />
              <li onClick={async () => {
                try {
                  window.open(`https://social-plugins.line.me/lineit/share?url=${encodeURIComponent(_url)}`, 'width=626,height=436, resizable=no');
                } catch (e) {
                  console.error(e);
                }
              }} />
              <li
                onClick={() => {
                  window.open(
                    `http://www.band.us/plugin/share?body=${_url}&route=${encodeURIComponent(_url)}`, "share_band", "width=410, height=540, resizable=no");
                }}
              />
              <li onClick={() => {
                window.open('https://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(_url), 'facebook-share-dialog', 'width=626,height=436')
              }} />

              <li
                onClick={() => {
                  window.open(`https://twitter.com/intent/tweet?text=쯩으로 백신 접종 정보 발급하세요.&url=${encodeURIComponent(_url)}`)
                }}
              >
              </li>
              <li
                ref={liRef as RefObject<HTMLLIElement>}
                onClick={() => {
                  copyToClipboard(_url);
                  handleCopiedDialog();
                }}
              >
                <input ref={inputRef as RefObject<HTMLInputElement>} id="copy__link" value={_url} style={{ width: 0 }} />
              </li>
            </ul>
          </div>
        </div>
        <div className={classes.guidance}>
          <div className={clsx(container)}>
            <div className="guidance__content">
              <div className="guidance__txt">
                방역패스 확인이 필요한 곳, <br />
                카페나 식당, 어디든 방역패스 보유 여부를 <br />
                쉽게 확인할 수 있도록 도와드려요
              </div>
            </div>
          </div>
        </div>
        <div className={classes.section__content2}>
          <div className={clsx(container, flex)} >
            <div style={{
              width: 260,
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}>
              <div className={classes.phone}>
                <img
                  src={phone__1}
                  style={{
                    width: 260,
                  }}
                  alt="백신접종 정보 필요할 땐 쯩"
                />
              </div>
            </div>
            <div
              className={clsx(flexColumn)}
              style={{ paddingTop: "0px", width: "680px", justifyContent: "center" }}
            >
              <div className={classes.section__desc}>
                <div className={classes.title}>
                  방역패스 서비스는 어떤 것인가요?
                </div>
                <div className={classes.desc}>
                  <div>
                    사용자의 방역패스 보유 여부를  쯩으로 간편하게 확인하는 서비스입니다. 쯩은 실시간으로 질병청에 사용자의 방역패스 보유 여부를 조회합니다.
                  </div>
                </div>
                <div className={classes.desc}>
                  <div className="img-round">
                    <img src={imgRound01} alt="img-round-1" />
                    <span>방역패스 확인이 필요할 때 나의 방역패스 정보를 보여줄 수 있어요.</span>
                  </div>
                  <div className="img-round">
                    <img src={imgRound02} alt="img-round-2" />
                    <span>제주도에서 여행할 때 제주안심코드로 QR체크인 시 방역패스 정보를 같이 증명할 수 있어요.</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container__2">
            <div className={clsx(container)}>
              <div className={classes.title}>
                방역패스 기준 정보 4가지는?
                </div>
              <div className={classes.desc}>
                방역패스는 4가지 기준이 조합된 정보로, 사용자가 방역패스를 보유했는지 아닌 지를
                확인하실 수 있습니다
                </div>
              <div className={classes.content}>
                <div>
                  <div>
                    접종 완료자
                  </div>
                  <div>
                    백신 추가 접종(부스터 샷) 완료 또는 2차 접종 후 14일 경과 180일 이내
                  </div>
                </div>
                <div>
                  <div>
                    완치자
                  </div>
                  <div>
                    코로나19 완치(확진 후 10일 경과) 후 180일 이내
                  </div>
                </div>
                <div>
                  <div>
                    PCR 음성 <br />확인자
                  </div>
                  <div>
                    PCR(코로나19 확진 검사) 음성 판정 후 48시간 이내
                  </div>
                </div>
                <div>
                  <div>
                    예외자
                  </div>
                  <div>
                    백신 접종 예외자 또는 건강상의 이유로 한시적 예외인 경우는 확정 후 180일 이내
                  </div>
                </div>
              </div>
              <div className={classes.listView}>
                <ul>
                  <li>

                    <span>
                      위의 정보는 방역지침 등에 따라 변경될 수 있습니다.
                    </span>
                  </li>
                  <li>
                    <span>
                      18세 미만인 경우 기본 접종(2차 접종) 완료 시에 ‘접종 완료자’입니다.
                    </span>
                  </li>
                  <li>
                    <span>
                      위의 정보 중 2가지 이상 조건을 충족하는 경우 최우선 조건으로만 표시될 수 있습니다.
                    </span> <br />
                    <span>추가접종 완료한 경우 PCR 검사 결과 음성이어도 ‘접종 완료자’로만 표시</span>
                  </li>
                  <li>
                    <span>방역패스를 보유한 경우라도 업장 유형에 따라 입장이 제한될 수 있습니다 질병청의 업장 별 방역패스 가이드를 참고해 주세요.</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className={clsx(classes.bgc, classes.guidance__vaccine__0)}>
          <div className={clsx(container)}>
            <div className={classes.vaccine__title}>
              <div className={classes.title}>
                쯩으로 방역패스 정보 확인하는 방법
              </div>
            </div>
            <SwipeSlider title={`쯩으로 방역패스 \n 정보 확인하는 방법`} imgArr={imgArr1} txtArr={txtArr1} subTxtArr={subTxtArr1} />
          </div>
        </div>
        <div className={clsx(classes.guidance__vaccine__0)}>
          <div className={clsx(container)}>
            <div className={classes.vaccine__title}>
              <div className={classes.title}>
                방역패스 이렇게 활용하세요
              </div>
            </div>
            <SwipeSlider title={`제주안심코드에서 \n 활용하기`} imgArr={imgArr2} txtArr={txtArr2} subTxtArr={subTxtArr2} />

            <div className={classes.borderBox} style={{
              margin: "110px 80px 0px"
            }}>
              <img src={getGifUrl(imgRound03)} alt="img-11" />
              QR 체크인 알림음은 접종 완료자에 해당하면 ‘접종 완료자입니다.’ 안내음이 나오고
              그 외에는 ‘띵동’ 기계음이 나와요.
            </div>
          </div>
        </div>
        <div className={classes.bgc}>
          <div className={clsx(container)}>
            <div className={classes.instruction}>
              <img src={imgRound04} style={{ width: 190, height: 190, marginRight: 50 }} alt="round-04" />
              <div >
                <div className="title">
                  방역패스 적용으로 쯩을 통한 <br />
                  코로나19 예방접종 정보 발급은 중단되었습니다
                </div>
                <div className="subtitle">
                  기 발급하신 접종 정보는 홈 화면의 ‘코로나19 예방접종 정보’ 배너를 선택하면 백신 접종 상세를 확인하실 수 있습니다. 단, 2차 접종까지 마지막으로 접종 정보를 업데이트 한 내용만 확인 가능합니다. 추가접종 정보는 확인할 수 없으니 이용에 참고 부탁드립니다
              </div>
              </div>
            </div>

          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

const useStyles = createUseStyles((theme: ThemeType) => ({
  wrap: {
    width: "100%",
    paddingTop: "8rem",
    "& div.flex": {
      display: "flex",
      margin: "0 auto"
    },
    "& > div.social-btn": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "50%",
      width: "5rem",
      height: "5rem",
      "& img": {
        width: "3rem",
        height: "3rem"
      },
      "&.kakaotalk": {
        backgroundColor: "rgb(233, 213, 71)"
      }
    }
  },
  bgc: {
    backgroundColor: theme.monoBg
  },
  phone: {
    width: 260,
    boxShadow: "0 6px 26px 0 rgba(0, 0, 0, 0.14)",
    borderRadius: 30,
    height: "auto",
  },
  header: {
    textAlign: "center",
    paddingTop: "9rem",
    paddingBottom: "9rem",
    "& div.intro__subtxt": {
      marginBottom: "1.8rem",
      ...theme.b2,
    },
    "& div.intro__maintxt": {
      ...theme.t1,
      fontWeight: theme.medium,
      fontColor: "#000",
      paddingBottom: "50px",
    },
    "& div.share__link": {
      "& ul": {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        margin: 0,
        padding: 0,
        listStyle: "none",
        '& li:nth-child(1)': {
          width: "36px",
          height: "36px",
          marginRight: 6,
          background: `url(${kakao}) no-repeat center center`,
          backgroundSize: "36px 36px",
          "&:hover": {
            background: `url(${kakao_nor}) no-repeat center center`,
            backgroundSize: "36px 36px"
          }
        },
        '& li:nth-child(2)': {
          width: "0px",
          height: "36px",
          marginRight: 6,
          background: `url(${kstory}) no-repeat center center`,
          backgroundSize: "36px 36px",
          "&:hover": {
            background: `url(${kstory_nor}) no-repeat center center`,
            backgroundSize: "36px 36px"
          }
        },
        '& li:nth-child(3)': {
          width: "36px",
          height: "36px",
          marginRight: 6,
          background: `url(${line}) no-repeat center center`,
          backgroundSize: "36px 36px",
          "&:hover": {
            background: `url(${line_nor}) no-repeat center center`,
            backgroundSize: "36px 36px"
          }
        },
        '& li:nth-child(4)': {
          width: "36px",
          height: "36px",
          marginRight: 6,
          background: `url(${band}) no-repeat center center`,
          backgroundSize: "36px 36px",
          "&:hover": {
            background: `url(${band_nor}) no-repeat center center`,
            backgroundSize: "36px 36px"
          }
        },
        '& li:nth-child(5)': {
          width: "36px",
          height: "36px",
          marginRight: 6,
          background: `url(${face}) no-repeat center center`,
          backgroundSize: "36px 36px",
          "&:hover": {
            background: `url(${face_nor}) no-repeat center center`,
            backgroundSize: "36px 36px"
          }
        },
        '& li:nth-child(6)': {
          width: "36px",
          height: "36px",
          marginRight: 6,
          background: `url(${twitter}) no-repeat center center`,
          backgroundSize: "36px 36px",
          "&:hover": {
            background: `url(${twitter_nor}) no-repeat center center`,
            backgroundSize: "36px 36px"
          }
        },
        '& li:nth-child(7)': {
          position: "relative",
          width: "36px",
          height: "36px",
          marginRight: 6,
          background: `url(${link}) no-repeat center center`,
          backgroundSize: "36px 36px",
          "&:hover": {
            background: `url(${link_nor}) no-repeat center center`,
            backgroundSize: "36px 36px"
          },
        },
      }
    }
  },
  guidance: {
    height: "390px",
    background: `url(${bgWave}) center right 0px no-repeat`,
    backgroundSize: "1090px 390px",
    margin: "0px auto",
    backgroundColor: "#87adff",
    "& div.guidance__content": {
      width: 940,
      padding: "12.9rem 0rem",
      height: "100%",
      margin: "0px auto",
      background: `url(${kio}) bottom 0px right 0px no-repeat`,
      backgroundSize: "420px 390px",
      "& div.guidance__txt": {
        ...theme.t3,
        fontWeight: theme.regular,
        color: theme.mono100,
      },
      "& div.btn-white": {
        width: "22.6rem",
        borderRadius: 6,
        padding: "11px 41px 11px 24px",
        background: `url(${ply}) right 20px center no-repeat`,
        backgroundSize: "16px 16px",
        fontWeight: theme.medium,
        backgroundColor: theme.mono100,
        cursor: "pointer"
      }
    }
  },
  section__content2: {
    padding: "130px 0px 0px 0px",
    "& > div": {
      justifyContent: "space-between",
    },
    "& > div.container__2": {
      paddingTop: 130,
    }
  },
  section__desc: {
    paddingLeft: "12rem",
  },
  desc: {
    fontWeight: theme.demiLight,
    marginBottom: "6rem",
    color: theme.mono50,
    lineHeight: "2.6rem",
    "& div.img-round": {
      display: "flex",
      alignItems: "center",
      marginBottom: 14,
      "& >img": {
        width: 66,
        height: 66,
        marginRight: 14,
      },
      "&:last-of-type": {
        marginBottom: 0,
      }
    },
  },
  borderBox: {
    ...theme.b3,
    color: theme.mono0,
    display: "flex",
    alignItems: "center",
    border: "1px solid #e1e1e1",
    padding: "40px 36px",
    borderRadius: 12,
    "& > img": {
      width: "80px",
      height: "80px",
      marginRight: "20px"
    },
  },
  instruction: {
    display: "flex",
    alignItems: "center",
    padding: "100px 0px",
    "& > div": {
      '& > div.title': {
        ...theme.b2,
        fontWeight: theme.medium,
        color: theme.mono0,
        marginBottom: 20,
      },
      '& > div.subtitle': {
        ...theme.b3,
        color: theme.mono50,
      }
    }

  },
  content: {
    display: "grid",
    rowGap: "20px",
    columnGap: "20px",
    gridTemplateColumns: "1fr 1fr",
    marginBottom: "40px",
    "& > div": {
      ...theme.b3,
      display: "flex",
      height: "120px",
      borderRadius: 12,
      boxShadow: "0px 6px 26px 0px rgba(0, 0, 0, 0.14)",
      "& > div:first-of-type": {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "140px",
        backgroundColor: "#6492ef",
        color: "#FFF",
        borderTopLeftRadius: 12,
        borderBottomLeftRadius: 12,
      },
      "& > div:last-of-type": {
        width: "320px",
        padding: "34px 26px",
      }
    }
  },
  listView: {
    paddingBottom: "130px",
    background: `url(${imgIllust}) right bottom no-repeat`,
    backgroundSize: "380px 280px",
    "& > ul": {
      ...theme.a1,
      lineHeight: "2.2rem",
      letterSpacing: "-0.02rem",
      listStyle: "none",
      padding: 0,
      margin: 0,
      color: theme.mono50,
      width: "52.8rem",
      paddingLeft: 12,
      "& > li": {
        position: "relative",
        marginBottom: 18,
        "&:before": {
          position: "absolute",
          left: "-1.2rem",
          content: "'·'",
        },
        "& > span": {
        },
        "&:last-of-type": {
          marginBottom: 0,
        }
      }
    }
  },
  guidance__vaccine__0: {
    padding: "13rem 0rem",
    "& > div > div.vaccine__subtxt": {
      width: "54rem",
      color: theme.mono50,
    }
  },
  vaccine__title: {
    display: "flex",
    justifyContent: "space-between",
    "& div.coming__soon": {
      ...theme.b2,
      fontWeight: theme.medium,
      width: "17rem",
      height: "44px",
      borderRadius: 22,
      padding: "6px 21px 8px",
      backgroundColor: theme.mint,
      color: theme.mono100,
      textAlign: "center",
    }
  },
  title: {
    ...theme.t3,
    fontWeight: theme.regular,
    marginBottom: "2rem"

  }
}))


export default VaccineGuidePage;