import React, { useCallback, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { createUseStyles } from "react-jss";
import { ThemeType } from "../styles/theme";
import { ROUTES } from "../constants";
import LogoW from "../assets/logoKioWL@2x.png";
import LogoB from "../assets/logoKioBM@2x.png";
import useScroll from "../hooks/useScroll";
import clsx from "clsx";

import { useGlobalRouter } from "../hooks/useRouter";

interface StyleProps {
  isBackgroundShown?: boolean;
  borderBottom?: boolean;
  hasPath?: boolean;
  pathname?: string;
}

const useStyles = createUseStyles((theme: ThemeType) => ({
  header: {
    width: "100%",
    display: "flex",
    justifyContent: "center",

    textAlign: "center",
    fontSize: "1.8rem",
    fontWeight: theme.medium,
    position: "fixed",
    zIndex: 99999,
    lineHeight: "2.8rem",
    background: ({ isShown }: { isShown: boolean; }) =>
      isShown ? "#fff" : "transparent",
    borderBottom: ({ isShown, borderBottom }: { isShown: boolean; borderBottom: boolean }) =>
      isShown
        ? `1px solid ${theme.monoDivider}`
        : borderBottom ? "1px solid #e1e1e1" : "1px solid rgba(255,255,255,0.26)",
    "& > div": {
      width: 940,
      //height: 80,
      display: "flex",
      justifyContent: "space-between",
      color: ({ isShown }: { isShown: boolean; pathname: string }) =>
        isShown ? theme.mono0 : theme.mono100,
      "& > div": {
        height: 80,
        paddingTop: 22,
        cursor: "pointer",
        "&:last-of-type": {
          paddingTop: 26,
          borderBottom: ({ isShown, pathname }: { isShown: boolean; pathname: string }) =>
            pathname === ROUTES.serviceCenter
              ? `4px solid ${isShown ? theme.mono0 : theme.mono100}`
              : "none",
        },
      },
    },
  },
  transition: {
    "-webkit-transition": "background-color 0.2s, -webkit-transform 0.2s",
    transition: "background-color 0.2s, -webkit-transform 0.2s",
  },
}));

const Header: React.FC<StyleProps> = ({ isBackgroundShown = true, borderBottom = false, hasPath = true }) => {
  const { scrollY } = useScroll();
  const [isShown, setShown] = useState<boolean>(isBackgroundShown);
  const { goTo } = useGlobalRouter();
  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname === "/" || pathname === "/service-center") {
      let axis = pathname === "/" ? 640 : 300;
      if (scrollY > axis && document.body.getBoundingClientRect().top < 0) {
        setShown(true);
      } else {
        setShown(false);
      }
    }

  }, [scrollY, pathname]);

  const classes = useStyles({ isShown, pathname, borderBottom });
  return (
    <header className={clsx(classes.header, classes.transition)}>
      <div>
        <div onClick={() => {
          goTo("home");
          // handleMenuClick(ROUTES.home)
        }}>
          <img
            src={isShown ? LogoB : LogoW}
            width="36"
            height="38"
            alt="Zzeung"
          />
        </div>
        {
          hasPath && (
            <div
              onClick={() => {
                goTo("serviceCenter");
                // handleMenuClick(ROUTES.serviceCenter)
              }}
              className={classes.transition}
            >
              고객센터
            </div>
          )
        }
      </div>
    </header>
  );
};

export default Header;
