import React, { ReactElement } from "react";
import { createUseStyles } from "react-jss";
import { ThemeType } from "../styles/theme";

interface Props {
  buttonList: ReactElement[];
  style?: React.CSSProperties;
}

const useStyles = createUseStyles((theme: ThemeType) => ({
  wrap: {
    display: "flex",
    "& > div:first-of-type": {
      marginRight: 12,
    },
  },
}));

const ButtonList: React.SFC<Props> = (props) => {
  const classes = useStyles(props);
  const { style = {}, buttonList = [] } = props;

  return (
    <div style={style} className={classes.wrap}>
      {buttonList}
    </div>
  );
};

export default ButtonList;
