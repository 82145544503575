import React, { useState, useEffect } from "react";
import { createUseStyles } from "react-jss";
import { ThemeType } from "../styles/theme";
import clsx from "clsx";
import { NoticeData, FaqData } from "../types";

interface Props {
  data: any[];
  itemComponent: any;
  selectedIdx: number;
  toggleItem: (idx: number) => void;
  dataType: "faq" | "notice";
}

export const executeLeftIfFaqType = (
  a: number | string,
  b: number | string,
  dataType: "faq" | "notice"
) => (dataType === "faq" ? a : b);

export const useListStyles = createUseStyles((theme: ThemeType) => ({
  wrap: {
    width: "100%",
    marginTop: ({ dataType }: Props) => executeLeftIfFaqType(28, 74, dataType),
    borderTop: `2px solid ${theme.mono50}`,
    borderBottom: `1px solid ${theme.monoDivider}`,
  },
  head: {
    padding: "17px 0px",
    "& > ul": {
      margin: 0,
      padding: 0,
      listStyle: "none",
      display: "flex",
      justifyContent: "space-between",
      color: theme.mono50,

      "& > li:nth-child(1)": {
        width: ({ dataType }: Props) =>
          executeLeftIfFaqType(146, 120, dataType),
        marginRight: ({ dataType }: Props) =>
          executeLeftIfFaqType(22, 0, dataType),
      },
      "& > li:nth-child(2)": {
        width: ({ dataType }: Props) =>
          executeLeftIfFaqType(773, 660, dataType),
        marginRight: ({ dataType }: Props) =>
          executeLeftIfFaqType(0, 0, dataType),
        textAlign: ({ dataType }: Props) =>
          executeLeftIfFaqType("center", "left", dataType),
      },
      "& > li:nth-child(3)": {
        width: ({ dataType }: Props) => executeLeftIfFaqType(0, 160, dataType),
      },
    },
  },
  list: {
    listStyle: "none",
    padding: 0,
  },
  item: {
    borderTop: `1px solid ${theme.monoDivider}`,
    "&.open": {
      background: theme.monoBg,
    },
    cursor: "pointer",
  },
  banner: {
    padding: "21px 0 23px 0",
    width: "100%",
    margin: "0 auto",
  },
  desc: {
    marginTop: 22,
    display: "none",
    width: "100%",
    textAlign: "left",
    "&.open": {
      display: "block",
      padding: ({ dataType }: Props) =>
        executeLeftIfFaqType(
          "0px 47px 0px 167px",
          "0px 40px 0px 120px",
          dataType
        ),
    },
    "& p": {
      marginBottom: 24,
      lineHeight: "2.6rem",
      "&:last-of-type": {
        marginBottom: 0,
      },
      "&.gray": {
        color: theme.mono50,
      },
    },
    "& > div.noticeImg": {
      display: "flex",
      justifyContent: "space-between",
      marginBottom: 24,
      "& > img": {
        width: "48%",
        borderRadius: "10px",
      }
    }
  },
}));

const List: React.FC<Props> = (props) => {
  const classes = useListStyles(props);
  const [_data, _setData] = useState<any[]>([]);

  const { data, itemComponent, selectedIdx, toggleItem, dataType } = props;

  useEffect(() => {
    _setData(data);
  }, [data]);

  const addOpenClassWhenSelected = (i: number) => selectedIdx === i && "open";

  return (
    <div className={classes.wrap}>
      <div className={classes.head}>
        <ul>
          <li>No</li>
          <li>제목</li>
          {dataType === "notice" && <li>등록일</li>}
        </ul>
      </div>
      <ul className={classes.list}>
        {_data.map((item: FaqData | NoticeData, i) => {
          return (
            <li
              key={i}
              className={clsx(classes.item, addOpenClassWhenSelected(i))}
            >
              <div
                onClick={() => toggleItem(i)}
                className={clsx(classes.banner)}
              >
                {React.createElement(itemComponent, {
                  item,
                  dataType,
                  idx: i,
                })}
                <div
                  className={clsx(classes.desc, addOpenClassWhenSelected(i))}
                  dangerouslySetInnerHTML={{
                    __html: item.desc,
                  }}
                ></div>
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default List;
