import React from "react";
import { createUseStyles } from "react-jss";
import { ThemeType } from "../styles/theme";
import { FaqData, NoticeData } from "../types";
import { categoryData } from "../constants/FaqData";
import { executeLeftIfFaqType } from "./List";

interface Props {
  item: FaqData | NoticeData;
  dataType: "faq" | "notice";
  idx: number;
}

const useStyles = createUseStyles((theme: ThemeType) => ({
  bar: {
    color: theme.mono0,
    lineHeight: "2.6rem",
    display: "flex",
    justifyContent: "space-between",
    "& span": {
      "&:nth-child(1)": {
        width: ({ dataType }: Props) =>
          executeLeftIfFaqType(146, 120, dataType),
        marginRight: ({ dataType }: Props) =>
          executeLeftIfFaqType(22, 0, dataType),
      },
      "&:nth-child(2)": {
        width: ({ dataType }: Props) =>
          executeLeftIfFaqType(773, 660, dataType),
        marginRight: ({ dataType }: Props) =>
          executeLeftIfFaqType(0, 0, dataType),
        textAlign: "left",
      },
      "&:nth-child(3)": {
        width: ({ dataType }: Props) => executeLeftIfFaqType(0, 160, dataType),
      },
    },
  },
  createdAt: {
    fontSize: "1.4rem",
    color: theme.mono50,
  },
}));

const ListItem: React.FC<Props> = (props) => {
  const classes = useStyles(props);
  const { item, dataType, idx } = props;
  return (
    <>
      <div className={classes.bar}>
        <span>
          {dataType === "faq"
            ? categoryData[(item as FaqData).category].label
            : idx + 1}
        </span>
        <span>{item.title}</span>
        {dataType === "notice" && <span>{(item as NoticeData).createdAt}</span>}
      </div>
    </>
  );
};

export default ListItem;
