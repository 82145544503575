const theme = {
  // Color
  accent: "rgb(57, 220, 206)",
  mint: "#39dcce",
  primaryBg: "#99afff",
  primarySol: "rgb(77, 135, 255)",
  primaryTxt: "rgb(39, 109, 255)",
  mono0: "rgb(34, 34, 34)",
  monoDivider: "rgb(225, 225, 225)",
  monoDisabled: "rgb(228, 228, 228)",
  mono50: "rgb(138, 138, 138)",
  monoBg: "rgb(246, 246, 246)",
  mono100: "#FFF",
  success: "rgb(57, 220, 156)",
  danger: "rgb(247, 68, 68)",
  // Font
  font: '"Roboto", "Noto Sans KR", sans-serif',

  demiLight: 300,
  regular: 400,
  medium: 500,
  bold: 700,

  "breakpoint-lg": "@media (min-width: 1920px)",
  "breakpoint-md": "@media (max-width: 960px)",

  // Layout
  gap: 20,

  t1: {
    fontSize: "5rem",
    lineHeight: "5.6rem",
    letterSpacing: "-0.06rem",
  },
  t3: {
    fontSize: "2.8rem",
    lineHeight: "4.4rem",
    letterSpacing: "-0.03rem",
  },
  b2: {
    fontSize: "2rem",
    lineHeight: "3rem",
    letterSpacing: "-0.04rem",
  },
  b3: {
    fontSize: "1.6rem",
    lineHeight: "2.6rem",
    letterSpacing: "-0.02rem",
  },
  a1: {
    fontSize: "1.4rem",
    lineHeight: "2.4rem",
    letterSpacing: "-0.04rem",
  },
};

export default theme;
export type ThemeType = typeof theme;
