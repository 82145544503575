import React from "react";

import { createUseStyles } from "react-jss";
import { ThemeType } from "../styles/theme";
import { useCommonStyles } from "../styles/common";
import clsx from "clsx";
import { useState } from "react";
import { ReactElement } from "react";

interface Props {
  menu: string[];
  components: ReactElement[];
  tabType?: "small" | "normal";
}

const useStyles = createUseStyles((theme: ThemeType) => ({
  wrap: {
    width: "100%",
    textAlign: "center",
    display: "flex",
    marginBottom: 20,
  },
  item: {
    fontFamily: `"Noto Sans KR", sans-serif`,

    fontSize: ({ tabType }: Props) =>
      tabType === "small" ? "1.6rem" : "2.8rem",
    lineHeight: ({ tabType }: Props) =>
      tabType === "small" ? "2.8rem" : "3.8rem",
    fontWeight: theme.regular,
    display: "inline-block",

    padding: ({ tabType }: Props) =>
      tabType === "small" ? "0px 0px 4px" : "0px 0px 12px",
    color: ({ tabType }: Props) =>
      tabType === "small" ? theme.mono50 : theme.mono0,
    marginRight: ({ tabType }: Props) => (tabType === "small" ? 40 : 50),
    textAlign: "center",
    cursor: "pointer",
    letterSpacing: "-0.06rem",
    "&.active": {
      color: ({ tabType }: Props) =>
        tabType === "small" ? theme.primarySol : theme.mono0,
      borderBottom: ({ tabType }: Props) =>
        tabType === "small"
          ? `4px solid ${theme.primarySol}`
          : `4px solid ${theme.mono0}`,
      fontWeight: ({ tabType }: Props) =>
        tabType === "small" ? theme.bold : theme.regular,
    },
  },
}));

const Tab: React.FC<Props> = (props) => {
  const { menu, components } = props;
  const classes = useStyles(props);
  const { flexColumn } = useCommonStyles();
  const [idx, selectedIdx] = useState<number>(0);

  return (
    <div className={clsx(classes.wrap, flexColumn)}>
      <ul className={classes.wrap}>
        {menu.map((item: string, i: number) => {
          return (
            <li
              key={i}
              onClick={() => selectedIdx(i)}
              className={clsx(idx === i && "active", classes.item)}
            >
              {item}
            </li>
          );
        })}
      </ul>
      {components[idx]}
    </div>
  );
};

export default Tab;
