import logo_nh from "../assets/partners/logo-nh@2x.png";
import logo_sinhan from "../assets/partners/logo-sinhan@2x.png";
import logo_hany from "../assets/partners/logo-hany@2x.png";
import logo_ibk from "../assets/partners/logo-ibk@2x.png";
import logo_sing from "../assets/partners/logo-sing@2x.png";
import logo_mirae from "../assets/partners/logo-mirae@2x.png";
import logo_sink from "../assets/partners/logo-sink@2x.png";
import logo_kyobo from "../assets/partners/logo-kyobo@2x.png";
import logo_ora from "../assets/partners/logo-ora@2x.png";
import logo_jej from "../assets/partners/logo-jej@2x.png";
import logo_posco from "../assets/partners/logo-posco@2x.png";
import logo_samsung from "../assets/partners/logo-samsung@2x.png";
import logo_danal from "../assets/partners/logo-danal@2x.png";

import logo_samz from "../assets/partners/logo-samz@2x.png";
import logo_bnk from "../assets/partners/logo-bnk@2x.png";
import logo_kb from "../assets/partners/logo-kb@2x.png";
import logo_you from "../assets/partners/logo-you@2x.png";
import logo_youan from "../assets/partners/logo-youan@2x.png";
import logo_hanh from "../assets/partners/logo-hanh@2x.png";
import logo_hi from "../assets/partners/logo-hi@2x.png";
import logo_best from "../assets/partners/logo-best@2x.png";
import logo_sanh from "../assets/partners/logo-sanh@2x.png";
import logo_dbk from "../assets/partners/logo-dbk@2x.png";
import logo_dbs from "../assets/partners/logo-dbs@2x.png";
import logo_kbs from "../assets/partners/logo-kbs@2x.png";
import logo_payco from "../assets/partners/logo-payco@2x.png";
import logo_ahnlab from "../assets/partners/logo-ahnlab@2x.png";
import logo_saramin from "../assets/partners/logo-saramin@2x.png";
import logo_bnkk from "../assets/partners/logo-bnkk@2x.png";
import logo_stx from "../assets/partners/logo-stx@2x.png";
import logo_gss from "../assets/partners/logo-gss@2x.png";
import logo_buzzni from "../assets/partners/logo-buzzni@2x.png";
import logo_cafe_24 from "../assets/partners/logo-cafe-24@2x.png";
import logo_sinhds from "../assets/partners/logo-sinhds@2x.png";
import logo_yanolja from "../assets/partners/logo-yanolja@2x.png";
import logo_fast from "../assets/partners/logo-fast@2x.png";
import logo_icerti from "../assets/partners/logo-icerti@2x.png";
import logo_yoti from "../assets/partners/logo-yoti@2x.png";
import logo_ssenstone from "../assets/partners/logo-ssenstone@2x.png";
import logo_initech from "../assets/partners/logo-initech@2x.png";
import logo_quant from "../assets/partners/logo-quant@2x.png";
import logo_moca from "../assets/partners/logo-moca@2x.png";
import logo_sone from "../assets/partners/logo-sone@2x.png";
import logo_suprema from "../assets/partners/logo-suprema@2x.png";
import logo_tee from "../assets/partners/logo-tee@2x.png";
import logo_arom from "../assets/partners/logo-arom@2x.png";
import logo_sejong from "../assets/partners/logo-sejong@2x.png";
import logo_blockchin from "../assets/partners/logo-blockchin@2x.png";
import logo_uppsala from "../assets/partners/logo-uppsala@2x.png";
import logo_kyth from "../assets/partners/logo-kyth@2x.png";
import logo_kpc from "../assets/partners/logo-kpc@2x.png";
import logo_kimnc from "../assets/partners/logo-kimnc@2x.png";
import logo_skuni from "../assets/partners/logo-skuni@2x.png";
import logo_blockkyhh from "../assets/partners/logo-blockkyhh@2x.png";
import logo_smaclo from "../assets/partners/logo-smaclo@2x.png";
import logo_8_percent from "../assets/partners/logo-8-percent@2x.png";
import logo_funda from "../assets/partners/logo-funda@2x.png";
import logo_binance from "../assets/partners/logo-binance@2x.png";
import logo_flybit from "../assets/partners/logo-flybit@2x.png";
import logo_modusign from "../assets/partners/logo-modusign@2x.png";
import logo_carplat from "../assets/partners/logo-carplat@2x.png";
import logo_hanknfc from "../assets/partners/logo-hanknfc@2x.png";
import logo_thebs from "../assets/partners/logo-thebs@2x.png";
import logo_planit from "../assets/partners/logo-planit@2x.png";
import logo_triple from "../assets/partners/logo-triple@2x.png";
import logo_kongyech from "../assets/partners/logo-kongyech@2x.png";
import logo_korda from "../assets/partners/logo-korda@2x.png";
import logo_lime from "../assets/partners/logo-lime@2x.png";
import logo_bithumb from "../assets/partners/logo-bithumb@2x.png";
import logo_comt from "../assets/partners/logo-comt@2x.png";
import logo_ktn from "../assets/partners/logo-ktn@2x.png";
import logo_nhn from "../assets/partners/logo-nhn@2x.png";
import logo_gra from "../assets/partners/logo-gra@2x.png";
import logo_del from "../assets/partners/logo-del@2x.png";

export const partnerListShort = [
  logo_nh,
  logo_sinhan,
  logo_hany,
  logo_sink,
  logo_ibk,
  logo_sing,
  logo_mirae,
  logo_kyobo,
  logo_ora,
  logo_posco,
  logo_samsung,
  logo_danal,
];

export const partnerListLong = [
  ...partnerListShort,
  logo_samz,
  logo_bnk,
  logo_kb,
  logo_you,
  logo_youan,
  logo_hanh,
  logo_hi,
  logo_best,
  logo_sanh,
  logo_dbk,
  logo_dbs,
  logo_kbs,
  logo_payco,
  logo_ahnlab,
  logo_saramin,
  logo_bnkk,
  logo_stx,
  logo_gss,
  logo_buzzni,
  logo_cafe_24,
  logo_sinhds,
  logo_yanolja,
  logo_fast,
  logo_icerti,
  logo_yoti,
  logo_ssenstone,
  logo_initech,
  logo_quant,
  logo_moca,
  logo_sone,
  logo_suprema,
  logo_tee,
  logo_arom,
  logo_sejong,
  logo_blockchin,
  logo_uppsala,
  logo_kyth,
  logo_kpc,
  logo_kimnc,
  logo_skuni,
  logo_blockkyhh,
  logo_smaclo,
  logo_8_percent,
  logo_funda,
  logo_binance,
  logo_flybit,
  logo_modusign,
  logo_carplat,
  logo_hanknfc,
  logo_thebs,
  logo_planit,
  logo_triple,
  logo_kongyech,
  logo_korda,
  logo_lime,
  logo_bithumb,
  logo_comt,
  logo_ktn,
  logo_nhn,
  logo_gra,
  logo_del,
  logo_jej,
];

// If the domain is v1, ROUTE uses "/", "service-center", "vaccine-guide";
// If the domain is local & v1, ROUTE uses "/", "service-center", "vaccine-guide";

// If the domain is v2 & local, ROUTE uses "/", "service-center", "vaccine-guide";
// If the domain is v2 & tbed, ROUTE uses "/", "service-center", "vaccine-guide";
// If the domain is v2 & live, ROUTE uses "/", "service-center", "vaccine-guide";

const _vaccine = process.env.REACT_APP_TYPE === "vax";

export const ROUTES = {
  home: "/",
  serviceCenter: "/service-center",
  vaccineGuide: _vaccine ? "/" : "/vaccine",
};
