import React from "react";
import Slider from "react-slick";
import { createUseStyles } from "react-jss";
import arrowNext from "../assets/arrow-next@2x.png";
import arrowPrev from "../assets/arrow-prev@2x.png";
import { ThemeType } from "../styles/theme";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../styles/custom__slick.css";

interface Props {
  title: string;
  imgArr: any[];
  txtArr: string[];
  subTxtArr?: string[];
}

const PrevArrow = (props: any) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        width: "42px",
        height: "82px",
        background: `url(${arrowPrev}) center no-repeat`,
        backgroundSize: "40px 80px"
      }}
      onClick={onClick}
    />
  );
}
const NextArrow = (props: any) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        width: "42px",
        height: "82px",
        background: `url(${arrowNext}) center no-repeat`,
        backgroundSize: "40px 80px"
      }}
      onClick={onClick}
    />
  );
}

const SwipeSlider: React.FC<Props> = (props) => {
  const {
    title,
    imgArr,
    txtArr,
    subTxtArr,
  } = props;
  const classes = useStyles();
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    customPaging: (i: number) => (
      <div className="slick__custom__dots">
        0{i + 1}
      </div>
    )
  };

  return (
    <div style={{
      width: "100%",
      height: "456px",
    }}>
      <Slider {...settings} >
        {
          imgArr.map((el, i) => {
            return (
              <div className={classes.slide__content}>
                <div className={classes.card}>
                  <img src={el} alt={title} />
                  <div className="card__desc">
                    <div className="card__title__txt">
                      {title.split("\n").map((el) => {
                        return (
                          <>
                            {el}<br />
                          </>
                        )
                      })}
                    </div>
                    <div className="card__num__txt">
                      {i + 1}.
                    </div>
                    <div className="card__desc__txt">
                      {txtArr[i]}
                    </div>
                    <div className="card__desc__subtxt">
                      {!!subTxtArr && subTxtArr[i]}
                    </div>
                  </div>
                </div>
              </div>
            )
          })
        }
      </Slider>
    </div>
  )
}

const useStyles = createUseStyles((theme: ThemeType) => ({
  slide__content: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "30px 80px 30px",
  },
  card: {
    display: "flex",
    borderRadius: 26,
    boxShadow: "0px 6px 26px 0px rgba(0, 0, 0, 0.14)",
    width: 780,
    height: 400,
    backgroundColor: theme.mono100,
    "& > img": {
      width: 480,
      height: 400,
    },
    "& div.card__desc": {
      padding: "40px 30px 0px",
      "& div.card__title__txt": {
        ...theme.b2,
        fontWeight: theme.medium,
        marginBottom: 131,
      },
      "& div.card__num__txt": {
        fontWeight: theme.medium,
        paddingBottom: 10,
        borderBottom: "1px solid #E1E1E1"
      },
      "& div.card__desc__txt": {
        marginTop: 10,
        fontWeight: theme.demiLight,
      },
      "& div.card__desc__subtxt": {
        ...theme.a1,
        display: "inline-block",
        padding: "0px 7px",
        backgroundColor: theme.monoBg,
        color: theme.mono50,
      }
    }
  }
}))

export default SwipeSlider;