import React, { useCallback, useState } from "react";

import { createUseStyles } from "react-jss";
import { ThemeType } from "../styles/theme";
import { List, ListItem } from "../components/";
import noticeData from "../constants/NoticeData";

const useStyles = createUseStyles((theme: ThemeType) => ({
  wrap: {
    width: "100%",
  },
}));

const NoticeSection: React.FC = () => {
  const classes = useStyles();

  const [selectedIdx, setIdx] = useState<number>(-1);
  const toggleItem = useCallback(
    (idx: number) => {
      setIdx(selectedIdx !== idx ? idx : -1);
    },
    [selectedIdx]
  );

  return (
    <>
      <div className={classes.wrap}>
        <List
          data={noticeData}
          itemComponent={ListItem}
          toggleItem={toggleItem}
          selectedIdx={selectedIdx}
          dataType="notice"
        />
      </div>
    </>
  );
};

export default NoticeSection;
