import React from "react";
import ReactDOM from "react-dom";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import { HashRouter as Router } from "react-router-dom";
import { BrowserRouter as BRouter } from "react-router-dom";
import "./styles/reset.css";
import "./styles/font.css";
import { ThemeProvider } from "react-jss";
import Theme from "./styles/theme";
import Routes from "./Routes";
import VaxRoutes from "./VaxRoutes";

const _type = process.env.REACT_APP_TYPE === "vax";

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={Theme}>
      {
        _type ? (
          <BRouter>
            <VaxRoutes />
          </BRouter>
        ) : (
            <Router>
              <Routes />
            </Router>
          )
      }
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
