import React, { useState, useCallback, useRef } from "react";
import { createUseStyles } from "react-jss";
import { ThemeType } from "../styles/theme";
import { List, ListItem } from "../components/";
import { faqData, categoryData } from "../constants/FaqData";
import searchIcon from "../assets/search-black@2x.png";
import resultNoneImg from "../assets/feedback-faq-non@2x.png";
import { RefObject } from "react";
import { CategoryData, FaqData } from "../types";
import clsx from "clsx";
import { useCommonStyles } from "../styles/common";

const useStyles = createUseStyles((theme: ThemeType) => ({
  wrap: {
    display: "flex",
  },
  input: {
    marginTop: 40,
    backgroundColor: theme.monoBg,
    background: `url(${searchIcon}) no-repeat right 24px top 20px`,
    backgroundSize: "30px auto",
    padding: "22px 74px 22px 24px",
    position: "relative",
    "& > input": {
      fontSize: "1.8rem",
      width: "100%",
      lineHeight: "2.8rem",
      color: theme.mono0,
      "&::placeholder": {
        color: theme.mono50,
      },
    },
    "& >span": {
      width: 35,
      height: 35,
      display: " inline-block",
      position: "absolute",
      right: 20,
      cursor: "pointer",
    },
  },
  categoryList: {
    textAlign: "left",
    marginTop: 40,
    height: 26,
    "& > div": {
      cursor: "pointer",
      display: "inline-block",
      paddingRight: 18,
      borderRight: `1px solid ${theme.monoDivider}`,
      marginLeft: 18,
      height: 18,
      lineHeight: "1.9rem",
      "&:first-of-type": {
        marginLeft: 0,
      },
      "&:last-of-type": {
        borderRight: 0,
      },
    },
  },
  none: {
    width: "100%",
    height: 500,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: 28,
    borderTop: `2px solid ${theme.mono50}`,
    borderBottom: `1px solid ${theme.monoDivider}`,
  },
  none__box: {
    textAlign: "center",
    "& > img": {
      marginBottom: 16,
    },
    "& > p": {
      fontSize: "1.8rem",
    },
  },
  category: {
    color: theme.mono50,
    "&.active": {
      fontWeight: theme.medium,
      color: theme.mono0,
    },
  },
}));

const FaqSection: React.SFC = () => {
  const classes = useStyles();
  const { flexColumn } = useCommonStyles();
  const [keyword, setKeyword] = useState<string>("");
  const keywordRef = useRef<HTMLInputElement>();

  const [selectedIdx, setIdx] = useState<number>(-1);
  const toggleItem = useCallback(
    (idx: number) => {
      setIdx(selectedIdx !== idx ? idx : -1);
    },
    [selectedIdx]
  );

  const [isSearchMode, setIsSearchMode] = useState<boolean>(false);
  const [selectedCategory, setCategory] = useState<number>(0);
  const [filteredData, setFilteredData] = useState<FaqData[]>(
    faqData.filter((item) => item.isFrequent)
  );

  const _filterData = useCallback(
    (keyword: string, selectedCategory: number) => {
      setIdx(-1);

      if (!!keyword) {
        setFilteredData(
          faqData.filter((item) => new RegExp(keyword, "i").test(item.title))
        );
        return;
      }

      if (selectedCategory === 0) {
        setFilteredData(faqData.filter((item) => item.isFrequent));
        return;
      }

      setFilteredData(
        faqData.filter((item) => item.category === selectedCategory)
      );
    },
    []
  );

  const handleSearch = useCallback(() => {
    setIsSearchMode(!!keyword ? true : false);
    _filterData(keyword, selectedCategory);
    (keywordRef.current as HTMLInputElement).blur();
  }, [keyword, selectedCategory, _filterData]);

  const handleKeyPress = useCallback(
    (e) => {
      const _value = e.target.value;
      const key = e.key === "Unidentified" ? _value[_value.length - 1] : e.key;
      if (key === "Enter") {
        handleSearch();
      }
    },
    [handleSearch]
  );

  return (
    <div className={clsx(classes.wrap, flexColumn)}>
      <div className={classes.input}>
        <input
          type="text"
          value={keyword}
          onChange={(e) => {
            setKeyword(e.target.value);
          }}
          onKeyPress={(e) => handleKeyPress(e)}
          ref={keywordRef as RefObject<HTMLInputElement>}
          placeholder="궁금한 것을 바로 검색해보세요"
        />

        <span onClick={() => handleSearch()} />
      </div>
      <div className={classes.categoryList}>
        {Object.values(categoryData)
          .sort((a: CategoryData, b: CategoryData) => a.order - b.order)
          .map((category) => {
            return (
              <div
                key={category.id}
                onClick={() => {
                  _filterData("", category.id);
                  setKeyword("");
                  setCategory(category.id);
                  setIsSearchMode(false);
                }}
              >
                <div
                  className={clsx(
                    classes.category,
                    !isSearchMode &&
                      selectedCategory === category.id &&
                      "active"
                  )}
                >
                  {category.label}
                </div>
              </div>
            );
          })}
      </div>
      {filteredData.length > 0 ? (
        <List
          data={filteredData}
          itemComponent={ListItem}
          toggleItem={toggleItem}
          selectedIdx={selectedIdx}
          dataType="faq"
        />
      ) : (
        <div className={classes.none}>
          <div className={classes.none__box}>
            <img
              src={resultNoneImg}
              alt="검색 결과가 없습니다"
              width="228"
              height="140"
            />
            <p>검색 결과가 없습니다</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default FaqSection;
