/** @format */

import { FaqData, CategoryData } from "../types";

const categoryData: Record<number, CategoryData> = {
  0: {
    id: 0,
    order: 0,
    label: "자주묻는질문",
  },
  1: {
    id: 1,
    order: 1,
    label: "휴대폰",
  },
  2: {
    id: 2,
    order: 2,
    label: "실명인증",
  },
  3: {
    id: 3,
    order: 3,
    label: "증명서",
  },
  4: {
    id: 4,
    order: 4,
    label: "운전면허인증",
  },
  5: {
    id: 5,
    order: 5,
    label: "기타",
  },
};

const faqData: FaqData[] = [
  {
    title: "휴대폰 인증의 유효기간이 있나요?",
    desc: `
      <p>발급받은 휴대폰 인증의 유효기간은 발급일로부터 2년입니다.</p>
    `,
    category: 1,
  },
  {
    title: "휴대폰을 분실하면 어떻게 해야 하나요?",
    desc: `
    <p>휴대폰을 분실해도 내 비밀번호 또는 생체 정보가 노출되지 않는 한 개인정보 유출의 위험은 없습니다. 앱을 재설치하면 서비스를 다시 이용할 수 있습니다.</p>
    `,
    category: 1,
  },
  {
    title: "휴대폰 기기 변경 시 기존에 발급받은 정보는 어떻게 되나요?",
    desc: `
      <p>보안상의 이유로 휴대폰 기기 변경 시 기존에 발급 받은 MyID와 증명서는 더 이상 사용이 불가합니다. 원활한 서비스 이용을 위해 기기 변경 시 앱을 재설치해주세요.</p>
    `,
    category: 1,
    order: 1,
    isFrequent: true,
  },
  {
    title: "휴대폰 유심을 바꾸거나 명의를 변경하면 어떻게 해야 하나요?",
    desc: `
      <p>휴대폰 유심을 바꾸거나 명의가 변경된 경우, 휴대폰 재인증이 필요합니다. 앱 데이터 삭제 또는 앱 재설치 후 인증을 다시 진행해주세요.</p>
    `,
    category: 1,
  },
  {
    title: "실명인증이 무엇인가요?",
    desc: `
    <p>NH 농협은행에서 발급하는 실명인증 인증서입니다.<br/>
    한번 발급받으면 앞으로 여러 금융기관에서 빠르고 간편하게 비대면 실명인증을 할 수 있어요.</p>
    `,
    category: 2,
  },
  {
    title: "실명인증은 어떻게 발급받나요?",
    desc: `
    <p>실명인증을 발급받으려면 신분증(운전면허증 또는 주민등록증)과 본인 명의의 은행 계좌(NH농협은행)가 필요합니다. <br/>
    쯩 앱을 설치하고 메인화면에서 ‘NH농협은행 실명인증 +’을 선택하면 발급 절차가 진행됩니다.</p>
    `,
    category: 2,
    order: 1,
    isFrequent: true,
  },
  {
    title: "실명인증은 어떻게 재발급받나요?",
    desc: `
     <p>발급된 실명인증은 쯩 앱 내 실명인증 상세 화면의 재발급 버튼을 누르면 됩니다.<br/>
    재발급 시 기존에 발급된 실명인증은 삭제되므로 확인 후 진행해주세요.</p>
    <p class="gray">
     재발급이 필요한 경우는 다음과 같습니다.<br/>
     - 유효기간 만료 <br/>
     - 신분증 정보가 변경<br/>
     - 폐기 처리</p>
    `,
    category: 2,
  },
  {
    title: "실명인증을 삭제할 수 있나요?",
    desc: `
    <p>쯩 앱 내 실명인증 상세 화면의 삭제 버튼을 눌러 삭제할 수 있습니다. 단, 삭제 후 더이상 사용할 수 없으며 추후 새로 발급받아야 합니다.</p>
    `,
    category: 2,
  },
  {
    title: "실명인증은 유효기간이 있나요?",
    desc: `
    <p>발급받은 실명인증의 유효기간은 발급일로부터 3개월입니다. <br/>
    이미 유효기간이 지났다면 재발급을 받을 수 있습니다.</p>
    `,
    category: 2,
    order: 1,
    isFrequent: true,
  },
  {
    title: "실명인증을 어떻게 사용하나요?",
    desc: `
    <p>NH농협은행 올원뱅크 앱에서 사용자 인증이 필요한 경우, 다음을 참고해주세요.</p>
    <p class="gray">
    1) 올원뱅크 올원PASS 발급 또는 송금한도 상향 시 NH농협은행 실명인증 방법을 선택<br/>
    2) 쯩에서 사용자 인증(생체 또는 비밀번호 인증) 후 사용 완료</p>
    `,
    category: 2,
    order: 1,
    isFrequent: true,
  },
  {
    title: "실명인증 정보가 없다고 나와요.",
    desc: `
    <p>실명인증을 발급받지 않은 경우에 해당 안내 메시지가 제시됩니다. 필요한 실명인증을 발급받은 뒤 다시 시도해주세요.</p>
    `,
    category: 2,
  },
  {
    title: "실명인증이 폐기되었다고 나와요.",
    desc: `
    <p>폐기 사유는 다음과 같습니다.</p>
    <p class="gray">
    - 발급기관에서 사용자 정보 오류로 폐기 처리<br />
    * 폐기된 경우, 실명인증을 재발급 받으면 다시 사용할 수 있습니다.</p>
    `,
    category: 2,
  },
  {
    title: "broof가 무엇인가요?",
    desc: `
    <p>broof란 발급받은 증명서를 한 곳에서 안전하게 관리하고 필요한 곳에 제출할 수 있는 블록체인 기반 디지털 증명서 서비스입니다.</p>
    `,
    category: 3,
  },
  {
    title: "broof 증명서 불러오기가 어떤 기능인가요?",
    desc: `
    <p>broof에 보관된 증명서들을 쯩에 불러옴으로써 발급받은 증명서들을 쯩에서 더욱 안전하고 편리하게 관리할 수 있습니다.</p>
    `,
    category: 3,
    order: 1,
    isFrequent: true,
  },
  {
    title: "증명서를 어떻게 불러오나요?",
    desc: `
    <p>broof에 발급받은 증명서가 있다면 발급받은 이메일 주소로 해당 증명서를 쯩 앱으로 불러올 수 있습니다.</p>
    `,
    category: 3,
  },
  {
    title: "증명서를 삭제할 수 있나요?",
    desc: `
    <p>사용자가 직접 등록한 증명서와 폐기된 증명서는 해당 증명서 상세 화면의 '삭제' 버튼을 눌러 삭제할 수 있습니다. 단, 발급 기관에서 발급한 증명서는 해당 기관만이 폐기할 수 있으며 삭제된 증명서는 복구할 수 없으니 유의해주세요.</p>
    `,
    category: 3,
  },
  {
    title: "증명서의 유효기간이 있나요?",
    desc: `
    <p>증명서는 유효기간이 따로 정해져 있지 않습니다.</p>
    `,
    category: 3,
  },
  {
    title: "증명서가 폐기되었다고 나와요.",
    desc: `
    <p>증명서를 발급한 발급기관은 해당 증명서를 폐기 처리할 수 있습니다. 폐기 사유는 발급기관에서 확인할 수 있습니다.</p>
    `,
    category: 3,
  },
  {
    title: "증명서를 다른 곳에 제출할 수도 있나요?",
    desc: `
    <p>앞으로 증명서를 간편하게 인증 한번으로 제출하는 서비스를 준비 중입니다.</p>
    `,
    category: 3,
    order: 1,
    isFrequent: true,
  },
  {
    title: "운전면허인증 서비스는 무엇인가요?",
    desc: `<p>실물 운전면허증을 미리 등록하여 운전 자격 확인이 필요할 때 간편하고 안전하게 인증할 수 있는 서비스 입니다. 경찰청 운전면허정보 진위 확인 시스템으로 안전하게 발급되며 블록체인 기술로 위변조를 검증할 수 있어 개인정보 유출 걱정 없이 이용할 수 있습니다.</p> 앞으로 운전면허인증을 이용한 서비스를 확장할 예정이니 기대해주세요.`,
    category: 4,
  },
  {
    title: "운전면허증 촬영이 안돼요.",
    desc: `<p>자동 촬영 진행이 안되는 경우, 화면 하단 '수동 촬영할게요'를 터치하여 수동 촬영 모드로 변경해서 촬영해 주세요.</p>[자동 촬영 지원 불가한 휴대폰 기종]<p class="gray">iPhone SE (iPhone8.4), iPhone 6 (iPhone7.2), LG X400 내수 (LGM-K121S), LG X400 내수 (LGM-K121K), LG X400 내수 (LGM-K121L), LG X400 글로벌 (LGM-X401S),LG X400 글로벌 (LGM-X401L), 갤럭시 A5 (SM-A500S), 갤럭시 A5 (SM-A500K), 갤럭시 A5 (SM-A500L), 갤럭시 A3 2016 (SM-A310N0), 갤럭시 A3 2017 (SM-A320F), 갤럭시 A30 (SM-A305N), 갤럭시 S8 (SM-G950N), 갤럭시 S8+ (SM-G955N), 갤럭시 Note 8 (SM-N950N), 갤럭시 S5 (SM-G900S), 갤럭시 S5 (SM-G900K), 갤럭시 S5 (SM-G900L), 갤럭시 S5 (SM-G906S), 갤럭시 S5 (SM-G906K), 갤럭시 S5 (SM-G906L) </p>`,
    category: 4,
  },
  {
    title: "휴대폰인증 정보와 면허증 정보가 일치하지 않는다고 나와요.",
    desc: `<p>쯩 앱 설치 후 휴대폰 본인확인 정보와 운전면허증 정보가 불일치한 경우입니다. 운전면허인증은 휴대폰 소유자와 운전면허증 소유자가 일치해야만 발급이 가능합니다. 운전면허증 정보 또는 쯩 휴대폰 인증 정보를 확인한 후 재시도 해주세요. </p>`,
    category: 4,
  },
  {
    title: "운전면허인증은 여러 휴대폰에서 이용할 수 있나요?",
    desc: `<p>운전면허인증은 본인 명의의 휴대폰 1대에서만 발급 가능합니다. 휴대폰을 변경하여 새로운 기기에서 발급 시 기존 운전면허인증은 폐기되니 유의해주세요. </p>`,
    category: 4,
  },
  {
    title: "운전면허증 촬영 후 실패했다고 나와요.",
    desc: `<p>쯩 앱 설치 시 등록했던 휴대폰 인증 정보와 다른 타인 운전면허증을 등록할 수 없습니다. 
    본인 운전면허증을 촬영해도 실패하는 경우는 아래로 예상됩니다. </p>
    <p class="gray">
    1) 운전면허증 촬영 후 인식 정보(이름, 운전면허번호, 생년월일, 일련번호 등)가 다른 경우, 재촬영 또는 인식 정보 수정 후 다시 시도해주세요. <br />
    2) 운전면허증 인식 정보를 올바르게 입력했음에도 실패한 경우 도로교통공단 고객센터(1577-1120)를 통해 확인 부탁드립니다.</p>`,
    category: 4,
  },
  {
    title: "운전면허증 정보 해킹 또는 도용 문제는 없나요?",
    desc: `<p>운전면허인증 정보는 휴대폰 내 안전한 영역에 저장되며 정보 제출이 필요할 때만 사용자 인증을 거쳐 이용됩니다. 
    또한, 블록체인에 운전면허인증 발급/제출 이력이 기록되어 데이터 위변조를 방지합니다. 휴대폰 기기 변경 시에는 더 안전한 사용을 위해 이전 기기에서 앱을 삭제하시길 바랍니다. 
    </p>`,
    category: 4,
  },
  {
    title: "운전면허인증 정보를 삭제할 수 있나요?",
    desc: `<p>발급된 운전면허인증은 운전면허인증 상세 화면 하단 '삭제' 선택 > 사용자 인증 후 삭제할 수 있습니다. 
    삭제 시 휴대폰 내에 저장된 운전면허인증 정보도 삭제되며 추후 이용을 원할 시 재발급을 받아야 합니다.</p>`,
    category: 4,
  },
  // 기타
  {
    title: "'코로나19 예방접종 정보'와 '제주안심코드'는 서비스 하지 않나요?",
    desc: `
    <p>질병청의 방역패스 정책 중단으로 인하여 코로나19 예방접종 정보 발급과 재발급은 중단되었습니다. 기 발급하신 접종 정보는 홈 화면의 ‘코로나19 예방접종 정보’ 배너를 선택하면 백신 접종 상세를 확인하실 수 있습니다. 단, 2차 접종까지 마지막으로 접종 정보를 업데이트 한 내용만 확인 가능합니다. 추가접종 정보는 확인할 수 없으니 이용에 참고 부탁드립니다. <br/> 
    <br/>
    제주안심코드 또한 방역 정책의 변경으로 인해 더이상 운영하지 않아 신규 발급이나 재발급을 중단하였습니다. 기존에 제주안심코드를 발급한 사용자 분들께서는 필요시 홈 화면의 제주안심코드' 배너를 선택하면 상세를 확인하거나 삭제할 수 있습니다.
    </p>
    `,
    category: 5,
    order: 0,
    isFrequent: true,
  },
  {
    title: "이용내역이 무엇인가요?",
    desc: `
    <p>이용내역을 통해 인증정보가 제출된 곳과 일시, 제공된 개인정보 상세 내역을 볼 수 있습니다. 내 개인정보를 내가 안전하게 관리할 수 있도록 하는 기능입니다.</p>
    `,
    category: 5,
  },
  {
    title: "이용내역은 어디서 볼 수 있나요?",
    desc: `
    <p>이용내역은 쯩 앱의 홈 화면 우측 상단의 이용내역 버튼을 통해 확인할 수 있습니다. 현재로부터 8개월 전까지의 내역을 제공하며, 그 이전의 내역은 자동 삭제됩니다.</p>
    `,
    category: 5,
    order: 1,
    isFrequent: true,
  },
  {
    title: "어플리케이션을 다시 설치했을 때 이전 이용내역을 볼 수 있나요?",
    desc: `
    <p>어플리케이션을 다시 설치할 경우, 데이터가 초기화되기 때문에 이전 이용내역을 확인할 수 없습니다.</p>
    `,
    category: 5,
  },
  {
    title: "제 휴대폰에서는 생체 인증 기능이 보이지 않아요.",
    desc: `
    <p>생체 인증 기능은 생체 인증 기능을 탑재한 일부 단말기에서만 이용이 가능합니다.</p>
    <p class="gray">
    1) AOS : Android 6.0(M) 이상 지문인식이 탑재된 단말부터 지원 가능<br/>
    2) iOS : iOS 12 이상 Touch ID 또는 Face ID가 탑재된 단말부터 지원 가능</p>
    `,
    category: 5,
  },
  {
    title: "생체 인증 시도 횟수를 초과했다고 나와요.",
    desc: `
    <p>생체 인증은 각 디바이스별 시스템 정책을 따르고 있습니다. </p>
    <p class="gray">
    1) AOS : 생체 인증 시도 횟수 초과 시, 30초 후 다시 생체 인증 사용<br/>
    2) iOS : 생체 인증 시도 횟수 초과 시, 잠금해제 후 생체 인증 사용</p>
    `,
    category: 5,
  },
  {
    title: "생체 인증이 등록되지 않아요.",
    desc: `
    <p>휴대폰 설정에서 생체 인증 사용이 설정되었는지 확인해주세요.</p>
    <p class="gray">
    1) iOS : 설정 - Face ID(또는 Touch ID) 및 암호<br />
    2) 안드로이드 : 설정 - 생체 인증 및 보안</p>
    `,
    category: 5,
  },
  {
    title: "비밀번호를 변경하고 싶어요.",
    desc: `
    <p>비밀번호는 ‘설정>인증 관리>비밀번호 변경'에서 변경할 수 있습니다. 비밀번호는 분실하면 다시 찾을 수 없으니 신중하게 설정해주세요.</p>
    `,
    category: 5,
  },
  {
    title: "비밀번호가 생각이 안나요.",
    desc: `
    <p>쯩은 비밀번호를 포함한 사용자 정보를 따로 저장하지 않습니다. 따라서 비밀번호 분실 시 되찾을 수 없으며 서비스를 초기화하여 사용해야 합니다. 앱을 삭제 후 재설치하면 서비스 초기화가 완료됩니다.</p>
    `,
    category: 5,
  },
  {
    title: "신분증 촬영하는데 카메라가 실행되지 않아요.",
    desc: `
    <p>앱 설정 내 카메라 권한이 허용되지 않았을 때, 촬영이 진행되지 않습니다. </p>
    <p class="gray">
    1) AOS : 디바이스 설정 > 애플리케이션 > 쯩 > 카메라 권한 허용
    2) iOS :  디바이스 설정 > 쯩 > 카메라 권한 허용</p>
    `,
    category: 5,
  },
  {
    title: "서비스를 그만 사용하고 싶은데 내 개인정보가 남아있지 않나요?",
    desc: `
    <p>앱을 지우면 발급받은 모든 정보가 삭제됩니다. 또한, 사용 시 등록한 정보는 사용자 기기 이외에 따로 저장되지 않습니다.</p>
    `,
    category: 5,
  },
  {
    title: "앱을 실행했더니 갑자기 앱 초기화하라고 나와요.",
    desc: `
    <p>쯩 앱은 고객 정보를 암호화하여 앱 내에 저장하고 있어 기기 변경 후 데이터를 이전한 경우에는 
    해당 데이터를 사용하지 못하도록 합니다.</p>
  
    <p>기기 변경의 경우 쯩 앱을 재설치하고 이용하도록 안내하고 있습니다. 
    다소 불편하시더라도 사용자 정보 보호를 위해 앱초기화를 부탁드립니다. 
    </p>
    `,
    category: 5,
  },
];

export { faqData, categoryData };
