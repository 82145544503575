/** @format */

import React from "react";

import { createUseStyles } from "react-jss";
import clsx from "clsx";

import { ThemeType } from "../styles/theme";
import { ButtonList, Title, Button, ButtonType } from "../components";

import { getGifUrl } from "../utils";
import { useCommonStyles } from "../styles/common";

import section__footer__gif from "../assets/web_footer.gif";

const useStyles = createUseStyles((theme: ThemeType) => ({
  section__footer: {
    borderTop: `1px solid ${theme.monoDivider}`,
    "& > div": {
      justifyContent: "space-between",
      padding: "180px 0 200px",
      "& > div:nth-child(1)": {
        marginTop: 20,
        "& > div:last-of-type": {
          marginTop: 90,
          "& p:last-of-type": {
            marginTop: 32,
            fontSize: "1.4rem",
            letterSpacing: "-0.016rem",
            lineHeight: "2.4rem",
          },
        },
        "& > ul.nav": {
          marginTop: 30,
          display: "flex",
          "& > li": {
            cursor: "pointer",
            fontSize: "1.4rem",
            lineHeight: "1.4rem",
            letterSpacing: "-0.02rem",
            paddingRight: 16,
            marginRight: 16,
            borderRight: `1px solid ${theme.monoDivider}`,
            "&:last-of-type": {
              paddingRight: 0,
              marginRight: 0,
              borderRight: "none",
            },
          },
        },
      },
      "& > div:nth-child(2)": {
        width: 360,
        height: 570,
        background: `url(${getGifUrl(
          section__footer__gif
        )}) center right no-repeat`,
        backgroundSize: "360px 570px",
      },
    },
  },
}));

const Footer: React.FC = () => {
  const { section__footer } = useStyles();

  const { wrap, container, flex, flexColumn, gray, bold } = useCommonStyles();

  return (
    <div className={clsx(section__footer, wrap)}>
      <div className={clsx(container, flex)}>
        <div className={clsx(flexColumn)}>
          <Title text={`인증이 필요할 때, 쯩`} color="black" />
          <ButtonList
            style={{ marginTop: 14 }}
            buttonList={[
              <Button
                text="Google Play"
                onClick={() => {
                  window.open(
                    "https://play.google.com/store/apps/details?id=com.iconloop.zzeung"
                  );
                }}
                buttonType={ButtonType.googlePlayBlack}
              />,
              <Button
                text="App Store"
                onClick={() => {
                  window.open("https://itunes.apple.com/app/id1512732128");
                }}
                buttonType={ButtonType.appleBlack}
              />,
            ]}
          />
          <div>
            <p className={bold}>(주) 아이콘루프 대표 김종협</p>
            <p className={gray}>
              서울특별시 중구 청계천로 100, 3층 / 02-2138-7026
              <br />
              사업자등록번호 647-81-00375 / 통신판매업신고 제
              2019-서울중구-0854호
              <br />
              COPYRIGHT ©2020 ICONLOOP Inc.
            </p>
          </div>
          <ul className="nav">
            <li
              onClick={() =>
                window.open("https://app.zzeung.id/app/webn/v4/#/tos?appVersion=10603")
              }
              className={bold}
            >
              서비스 이용약관
            </li>
            <li
              onClick={() =>
                window.open("https://app.zzeung.id/app/webn/v4/#/tos/2?appVersion=10603")
              }
              className={bold}
            >
              개인정보처리방침
            </li>
          </ul>
        </div>
        <div></div>
      </div>
    </div>
  );
};

export default Footer;
