import { NoticeData } from "../types";
import noticeBg1 from "../assets/notice_bg_1.png";
import noticeBg2 from "../assets/notice_bg_2.png";
import noticeBg3 from "../assets/notice_bg_3.png";
// import noticeBg4 from "../assets/notice_bg_4.png";
// import noticeBg5 from "../assets/notice_bg_5.png";
// import noticeBg6 from "../assets/notice_bg_6.png";

const noticeData: NoticeData[] = [
  {
    title: "신한은행 실명인증 서비스 종료 안내",
    desc: `
    <p>2022년 10월 31일 (월)<br/>
    <strong>신한은행 실명인증 서비스가 종료되었습니다.</strong>
    </p>
    <p>
    <p>신한은행과 함께 했던 블록체인 금융 실명인증 서비스가 2022년 10월 31일 18시에 종료되었습니다.<br/></p>
    <p>그에 따라 신한은행의 금융 실명인증 신규 발급과 재발급 그리고 기존에 발급받은 금융 실명인증을 제출하는 서비스가 모두 중단되었습니다.<br/></p>
    <p>이용에 참고 부탁드립니다.<br/></p>
    <p>* 쯩은 사용자의 개인정보를 저장하지 않습니다. 신한은행 금융 실명인증 서비스와 관련한 어떤 개인정보도 쯩 서버에 저장되지 않으니 안심하셔도 됩니다!<br/></p>
    </p>
    <p>
    @쯩 서비스팀 드림
    </p>
    </>
    `,
    createdAt: "2022.10.31",
  },
  {
    title: "신한은행 실명인증 서비스 종료 안내",
    desc: `
    <p>2022년 09월 30일 (금)<br/>
    <strong>신한은행 실명인증 서비스가 중단될 예정입니다.</strong>
    </p>
    <p>
    <p>신한은행과 함께 했던 블록체인 금융 실명인증 서비스가 2022년 10월 31일 18시에 종료될 예정입니다.<br/></p>
    <p>그에 따라 2022년 6월 30일 18시부터 신한은행의 금융 실명인증 신규 발급과 재발급이 중단되었습니다.<br/></p>
    <p>기존에 발급받은 신한은행 금융 실명인증은 유효기간이 만료되지 않은 경우 서비스 종료 전까지 사용하실 수 있습니다.<br/></p>
    <p>이용에 참고 부탁드립니다.<br/></p>
    <p>* 쯩은 사용자의 개인정보를 저장하지 않습니다. 신한은행 금융 실명인증 서비스와 관련한 어떤 개인정보도 쯩 서버에 저장되지 않으니 안심하셔도 됩니다!<br/></p>
    </p>  
    <p>
    @쯩 서비스팀 드림
    </p>
    </>
    `,
    createdAt: "2022.09.30",
  },
  {
    title: "신한은행 실명인증 서비스 종료 안내",
    desc: `
    <p>2022년 05월 30일 (월)<br/>
    <strong>신한은행 실명인증 서비스가 중단될 예정입니다.</strong>
    </p>
    <p>
    <p>신한은행과 함께 했던 블록체인 금융 실명인증 서비스가 2022년 10월 말에 종료될 예정입니다.<br/></p>
    <p>그에 따라 2022년 6월 30일 18시부터 신한은행의 금융 실명인증 신규 발급과 재발급이 중단됩니다.<br/></p>
    <p>기존에 발급받은 신한은행 금융 실명인증은 유효기간이 만료되지 않은 경우 서비스 종료 전까지 사용하실 수 있습니다.<br/></p>
    <p>이용에 참고 부탁드립니다.<br/></p>
    <p>* 쯩은 사용자의 개인정보를 저장하지 않습니다. 신한은행 금융 실명인증 서비스와 관련한 어떤 개인정보도 쯩 서버에 저장되지 않으니 안심하셔도 됩니다!<br/></p>
    </p>  
    <p>
    @쯩 서비스팀 드림
    </p>
    </>
    `,
    createdAt: "2022.05.30",
  },
  {
    title: "방역 서비스 종료 안내",
    desc: `
    <p>2022년 04월 25일 (월)<br/>
    <strong>1. 제주안심코드 서비스가 중단되었습니다.</strong>
    </p>
    <p>
    <strong>제주안심코드로 제주도의 방역에 참여해 주셔서 감사합니다!</strong><br/>
    방역 지침 변경으로 제주안심코드 서비스가 중단됩니다.<br/>
    제주안심코드 QR 체크인을 하지 않아도 모든 다중이용시설 출입이 가능합니다. 기존에 발급받은 제주안심코드는 삭제할 수 있습니다.<br/>
    (*삭제 방법: 쯩 메인화면에서 제주안심코드 배너를 선택하여 상세화면에서 삭제)<br/>
    또한 발급 기능 중단으로 제주안심코드 신규 발급과 재발급이 불가합니다.<br/>
    이용에 참고 부탁드립니다.
    </p>  
    <p>
    <strong>2. 방역패스는 서비스하지 않습니다.</strong>
    </p>
    <p>올해, 2022년 초반 계획되어 있던 방역패스 서비스 론칭이 방역 지침 변경에 따라 취소되었습니다.</p>
    <p>
    <strong>3. 발급받은 ‘코로나19 예방접종 정보’는 쯩에서 확인 가능합니다.</strong>
    </p>
    <p>기존에 쯩에서 발급한 ‘코로나19 백신 접종 정보’는 계속해서 쯩에서 확인할 수 있습니다. 그러나 신규 발급과 재발급(갱신)은 지원하지 않습니다. 이용에 참고 부탁드립니다.</p> 
    <p>
    <strong>그동안 쯩과 함께 방역에 동참해 주셔서 감사합니다.</strong>
    </p>
    <p>* 쯩은 사용자의 개인정보를 저장하지 않습니다. 제주안심코드와 코로나19 예방접종 정보 서비스와 관련한 어떤 개인정보도 쯩 서버에 저장되지 않으니 안심하셔도 됩니다!</p>
    <p>
    @쯩 서비스팀 드림
    </p>
    </>
    `,
    createdAt: "2022.04.25",
  },
  {
    title: "백신 정보 발급 종료 안내",
    desc: `
    <p>2022년 01월 27일 (목) <br />
    <strong>코로나19 예방접종 정보 발급 종료 예정입니다.</strong>
    </p>
    <p>
    방역 정책이 방역패스로 변경되어 쯩에서는 코로나19 예방접종 정보 발급이 종료될 예정입니다. 
    </p>
    <p>
    <strong>백신 정보 발급이 종료되는 대신, 변경된 방역 정책에 맞춰 ‘방역패스 실시간 확인 서비스’를 준비하고 있습니다. </strong>
    </p>
    <p>
    <strong>‘방역패스 실시간 확인 서비스’</strong>는 업장 출입 등 방역패스 정보 확인이 필요할 때, 사용자가 실시간으로 방역패스 보유 유무와 보유한 방역패스 유형을 확인하실 수 있도록 합니다.
    </p>
    <p>
    * 방역패스 유형은 접종 완료자, 완치자, PCR 음성, 예외자 입니다. <br/>
    * 제주안심코드를 이용하여 제주도 내에서 방문 QR 체크인 시, 실시간으로 방역패스 정보가 확인됩니다. 사용자가 별도로 방역패스를 호출하지 않아, 편리하게 이용하실 수 있습니다.<br/>
    * 백신 정보 발급은 ‘방역패스 실시간 확인 서비스’ 개시와 함께 종료됩니다.<br/>
    * 백신 정보 발급이 종료되어도 기존에 쯩에서 발급한 백신 정보는 확인할 수 있습니다. <br/>
    </p>
    <p>
    @쯩 서비스팀 드림
    </p>
    </>
    `,
    createdAt: "2022.01.27",
  },
  {
    title: "법정대리인 동의 절차 및 백신 유효기간 만료 적용 안내",
    desc: `
    <p>2022년 01월 11일 (수) <br />
    <strong>1. 만 14세 미만의 아동도 쯩 서비스를 이용할 수 있게 업데이트되었습니다!</strong>
    </p>
    <p>
    쯩 서비스 약관을 동의하고 만 14세 미만 아동의 휴대폰 인증을 진행합니다. <br />
    진행하시면 아래와 같은 화면이 나옵니다.
    </p>
    <div class="noticeImg">
      <img src="${noticeBg2}" alt="notice_1" />
    </div>
    <p>
    해당 화면에서 약관 동의를 하시고 보호자의 휴대폰 본인인증을 진행하시면 됩니다. <br/><br/>
    통신사 선택 후 문자 인증을 선택하여 보호자의 정보를 입력해 진행해 주시면 됩니다. <br/><br/>
    <strong>이제 만 14세 미만의 사용자도 법정대리인의 동의, 인증 절차를 거쳐 쯩 서비스를 이용해 보세요. </strong>
    </p>
    <p>
    <strong>2. 코로나19 예방접종 정보의 유효기간 만료 표시가 추가되었습니다!</strong> <br /><br />
    이제 기간 만료 유무를 아래 화면(코로나19 예방접종 정보 화면과 제주안심코드 QR 체크인 완료 화면)에서 확인하실 수 있습니다.
    </p>
    <div class="noticeImg">
      <img src="${noticeBg3}" alt="notice_1" />
    </div>
    <p>
    유효기간에 대해 질병청에서 발표한 자세한 내용은 FAQ에서 확인해 주세요.
    </p>
    <p>
    <strong>일상을 회복하기 위해 꼭 필요한 코로나19 예방접종 확인, 쯩이 도와드릴게요!</strong>
    <p>
    @쯩 서비스팀 드림
    </p>
    </>
    `,
    createdAt: "2022.01.11",
  },
  {
    title: "코로나19 예방접종 정보 발급 서비스 안내",
    desc: `
    <p>2021년 12월 01일(수) <br />
    쯩에 코로나19 예방접종 정보 발급 서비스가 들어왔습니다!</p>
    <div class="noticeImg">
      <img src="${noticeBg1}" alt="notice_1" />
    </div>
    <p>
    이제 카페나 식당, 코로나19 예방 접종 확인이 필요한 곳 어디든,
    쉽게 접종 여부를 확인할 수 있도록 쯩이 도와드려요.
    </p>
    <p>
    <strong>1차 접종? 2차 접종? 내 접종 정보를 확인하세요.</strong> <br />
  홈 화면의 [코로나 19 예방접종 정보] 배너에서 나의 접종 정보를 확인해 보세요. <br />
  접종 차수, 백신 종류, 접종일, 백신 맞은 지 14일이 지났는지까지 한 번에 보실 수 있어요.
    </p>
    <p>
    <strong>백신 접종 정보가 필요할 때, 쉽고 빠르게 증명 완료!</strong> <br />
  백신을 정말 맞았는지 확인이 필요할 때 내 접종 정보를 보여주세요!
    </p>
    <p>
    * 그리고 한가지 더 <br />
    [코로나19 예방접종 정보] 화면에서 내 접종 정보를 더욱 쉽게 보여주려면 ‘진입 시 인증 건너뛰기’를 사용해 보세요. 해당 기능을 ‘ON’하시면 추가적인 인증 없이 바로 백신 접종 정보를 보여줄 수 있습니다.
    </p>
    <p>
    <strong>일상을 회복하기 위해 꼭 필요한 코로나19 예방접종 확인, 쯩이 도와드릴게요!</strong>
    <p>
    @쯩 서비스팀 드림
    </p>
    </>
    `,
    createdAt: "2021.12.01",
  },
  {
    title: "SKT 본인확인 서비스 시스템 점검",
    desc: `
      <p>2021년 2월 17일(수) SKT 본인확인 서비스 시스템 점검 예정이오니 이용에 참고 부탁드립니다.<br />
      <br />
      중단 일시: 2021년 2월 17일(수) 19:00 ~ 21:00<br />
      중단 사유: SKT본인확인시스템 기능개선 작업<br />
      중단 업무: SKT MNO, MVNO 본인인증 수차례 순단<br />
      <br />
      * 중단 시간은 작업 진행에 따라 변경될 수 있습니다.<br />
      안정적인 서비스 제공을 위해 더욱 노력하겠습니다.<br />
      </p>
      <p>@쯩 서비스팀 드림</p>
    `,
    createdAt: "2021.02.17",
  },
  {
    title: "LGU+ 본인확인 서비스 시스템 점검",
    desc: `
      <p>2020년 11월 11일(수) LGU+본인확인 서비스 시스템 점검 예정이오니 이용에 참고 부탁드립니다.<br />
      <br />
      중단 일시: 2020년 11월 11일(수) 3:00 ~ 4:00<br />
      중단 사유: LGU+ 휴대폰 본인확인 SMSGW 긴급 작업<br />
      중단 업무: LGU+ 본인확인 서비스(알뜰폰 포함)<br />
      <br />
      * 중단 시간은 작업 진행에 따라 변경될 수 있습니다.<br />
      안정적인 서비스 제공을 위해 더욱 노력하겠습니다.<br />
      </p>
      <p>@쯩 서비스팀 드림</p>
    `,
    createdAt: "2020.11.10",
  },
  {
    title: "LGU+ 본인확인 서비스 시스템 점검",
    desc: `
      <p>2020년 11월 02일(월) LGU+본인확인 서비스 시스템 점검 예정이오니 이용에 참고 부탁드립니다.<br />
      <br />
      중단 일시: 2020년 11월 02일(월) 15:00 ~ 17:00<br />
      중단 사유: LGU+ 본인확인 서비스 시스템 점검<br />
      중단 업무: LGU+본인확인 서비스(알뜰폰 포함)<br />
      <br />
      * 중단 시간은 작업 진행에 따라 변경될 수 있습니다.<br />
      안정적인 서비스 제공을 위해 더욱 노력하겠습니다.<br />
      </p>
      <p>@쯩 서비스팀 드림</p>
    `,
    createdAt: "2020.11.02",
  },
  {
    title: "SKT 본인확인 서비스 시스템 점검",
    desc: `
      <p>2020년 10월 30일(금) SKT본인확인 서비스 시스템 점검 예정이오니 이용에 참고 부탁드립니다.<br />
      <br />
      중단 일시: 2020년 10월 30일(금) 3:00 ~ 6:30<br />
      중단 사유: SKT 본인확인 서비스 시스템 점검<br />
      중단 업무: SKT 본인확인 서비스(알뜰폰 포함)<br />
      <br />
      * 중단 시간은 작업 진행에 따라 변경될 수 있습니다.<br />
      안정적인 서비스 제공을 위해 더욱 노력하겠습니다.<br />
      </p>
      <p>@쯩 서비스팀 드림</p>
    `,
    createdAt: "2020.10.28",
  },
  {
    title: "LGU+ 본인확인 서비스 시스템 점검",
    desc: `
      <p>
        2020년 10월 28일(수) LGU+본인확인 서비스 시스템 점검 예정이오니 이용에 참고 부탁드립니다.<br />
        <br />
        중단 일시: 2020년 10월 28일(수) 2:00 ~ 5:00<br />
        중단 사유: LGU+ 본인확인 서비스 시스템 점검<br />
        중단 업무: LGU+ 본인확인 서비스(알뜰폰 포함)<br />
        <br />
        * 중단 시간은 작업 진행에 따라 변경될 수 있습니다.<br />
        안정적인 서비스 제공을 위해 더욱 노력하겠습니다.<br />
      </p>
      <p>@쯩 서비스팀 드림</p> 
    `,
    createdAt: "2020.10.27",
  },
  {
    title: "SKT 본인확인 서비스 시스템 점검",
    desc: `
      <p>
        2020년 10월 25일(일) SKT 본인확인 서비스 시스템 점검 예정이오니 이용에 참고 부탁드립니다.<br />
        <br />
        작업 일시: 2020년 10월 25일(일) 2:00 ~ 3:00<br />
        작업 영향: SKT 본인확인 서비스 순단<br />
        <br />
        안정적인 서비스 제공을 위해 더욱 노력하겠습니다.<br />
      </p>

      <p>@쯩 서비스팀 드림</p> 
    `,
    createdAt: "2020.10.23",
  },
  {
    title: "쯩 서비스를 오픈했어요.",
    desc: `
      <p>나를 증명하기 위해 매 번 겪였던 불편함!<br />쯩이 해결해드립니다.<br />자기 증명 서비스 시작의 첫 걸음에 함께 해주세요.<br />앞으로 더욱 다양한 MyID와 증명서 서비스를 추가할 예정이니 기대해주세요!(꾸벅)</p><p>@쯩 서비스팀 드림</p> 
    `,
    createdAt: "2020.06.04",
  },
];

export default noticeData;
