import React from "react";
// @ts-ignore
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import { createUseStyles } from "react-jss";
import qs from "qs";
import { ThemeType } from "../styles/theme";
import {
  ButtonList,
  Title,
  Button,
  ButtonType,
  Slider,
  Footer,
  Tab,
  Header
} from "../components";
import { partnerListShort, partnerListLong } from "../constants/";
import { getGifUrl } from "../utils";

import { useGlobalRouter } from '../hooks/useRouter';

import section__visual__bg from "../assets/bg1@2x.png";

import section__message__gif from "../assets/web_kio.gif";

import section__content1__gif from "../assets/phone-area-card.gif";
import section__content1__png from "../assets/account-01@3x.png";
import section__content1__myid from "../assets/myid.png";

import section__content2__driver__gif from "../assets/web_driver.gif";

import section__content3__gif from "../assets/web_certificate.gif";
import section__content4__gif from "../assets/web_secure.gif";

import section__contact__pdf from "../assets/myid_brochure_v1.0.pdf";

import icon__viewmore from "../assets/icon-viewmore@2x.png";
import icon__close from "../assets/icon-clo@2x.png";

import clsx from "clsx";
import { useCommonStyles } from "../styles/common";

const useStyles = createUseStyles((theme: ThemeType) => ({
  section__header: {
    position: "absolute",
    display: "flex",
    justifyContent: "center",
    "& > div": {
      width: 940,
      zIndex: 1,
      paddingTop: 263,
      justifyContent: "space-between",
      "& > div > p": {
        marginBottom: 20,
      },
    },
  },
  section__visual: {
    height: 720,
    [theme["breakpoint-lg"]]: {
      "--width": "calc(100vw * 720)",
    },
    background: `url(
      ${section__visual__bg}
    ) top center no-repeat`,
    backgroundSize: "cover",
  },
  section__message: {
    padding: "75px 0px 0px 0px",
    "& > div": {
      alignItems: "flex-end",
      justifyContent: "center",
      height: 628,
      background: `url(${getGifUrl(
        section__message__gif
      )}) top center no-repeat`,
      backgroundSize: "700px 425px",
      "& > p": {
        fontSize: "2.8rem",
        width: "100%",
        background: theme.monoBg,
        padding: "56px 0px",
      },
    },
  },
  section__content1: {
    padding: "164px 0px",
    "& > div": {
      height: 452,
      justifyContent: "space-between",
      "& > div:nth-child(1)": {
        width: 473,
        position: "relative",
        "& > div": {
          "&:nth-child(1)": {
            width: 276,
            height: "430px",
            background: `url(${getGifUrl(
                section__content1__gif
            )}) center/276px no-repeat`,
          },
        },
      },
      "& > div:nth-child(2)": {
        paddingTop: 74,
      },
    },
  },
  section__content2: {
    padding: "99px 0px",
    "& > div": {
      height: 582,
      marginBottom: 64,
      justifyContent: "space-between",
      "& > div:nth-child(1)": {
        marginTop: 139,
      },
      "& > div:nth-child(2)": {
        width: 376,
        position: "relative",
      },
    },
  },
  section__content3: {
    padding: "99px 0px",
    "& > div": {
      height: 582,
      justifyContent: "space-between",
      "& > div:nth-child(1)": {
        width: 473,
        position: "relative",
        "& > div": {
          "&:nth-child(1)": {
            width: 276,
          },
        },
      },
      "& > div:nth-child(2)": {
        paddingTop: 139,
      },
    },
  },
  section__content4: {
    padding: "210px 0px",
    "& > div": {
      height: 360,

      justifyContent: "space-between",
      "& > div:nth-child(1)": {
        marginTop: 43,
      },
      "& > div:nth-child(2)": {
        width: 460,
        position: "relative",
        "& > div": {
          "&:nth-child(1)": {
            width: 450,
            top: 45,
          },
        },
      },
    },
  },
  section__contact: {
    padding: "130px 0px",
    background: theme.primarySol,
    "& > div": {
      textAlign: "center",
      alignItems: "center",
      "& > div": {
        width: 452,
        justifyContent: "space-between",
      },
    },
  },
  section__partners: {
    padding: "200px 0px 170px",
    "& > div:nth-child(1)": {
      justifyContent: "space-between",
    },
    "& > ul": {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "flex-start",
      marginTop: 90,
      "& li": {
        width: 220,
        height: 80,
        backgroundSize: "220px auto",
        marginTop: 100,
        marginRight: 20,
        "&:nth-child(4n)": {
          marginRight: 0,
        },
        "&:nth-child(-n+4)": {
          marginTop: 0,
        },
      },
    },
    "& .toggle": {
      cursor: "pointer",
      margin: "100px auto 0px",
      fontWeight: theme.demiLight,
      lineHeight: "1.75rem",
      "&.open": {
        width: 76,
        background: `url(${icon__viewmore}) right center/28px no-repeat`,
      },
      "&.close": {
        width: 62,
        background: `url(${icon__close}) right center/28px no-repeat`,
      },
    },
  },
  tabItem: {
    "& > p": {
      paddingTop: 20,
      textAlign: "left",
    },
    "& > p > img": {
      marginTop: 20,
    },
    "& > div": {
      marginTop: 20,
    },
  },
}));

function hashLinkScroll(
  location: any,
  togglePartners: (togglePartners: boolean) => void
) {
  const { search } = location;
  const { section } = qs.parse(search, { ignoreQueryPrefix: true });
  if (!!section) {
    setTimeout(() => {
      const element = document.getElementById(section as string);
      if (element) {
        element.scrollIntoView();
        togglePartners(true);
      }
    }, 0);
  }
}

const _env = process.env.REACT_APP_ENV === 'tbed';

const MainPage: React.FC = () => {
  const {
    section__header,
    section__visual,
    section__message,
    section__content1,
    section__content2,
    section__content3,
    section__content4,
    section__contact,
    section__partners,
    tabItem,
  } = useStyles();

  const {
    wrap,
    container,
    flex,
    flexColumn,
    quote,

    phone,

    white,
    // gray,
  } = useCommonStyles();
  const { goTo } = useGlobalRouter();

  const location = useLocation();
  const [isPartnersShown, togglePartners] = React.useState<boolean>(false);
  const _partnersData = isPartnersShown ? partnerListLong : partnerListShort;

  React.useEffect(() => {
    hashLinkScroll(location, togglePartners);
  }, [location]);

  return (
    <>
      <Helmet>
        <title>쯩 - 인증이 필요할 때, 쯩</title>
      </Helmet>
      <Header />
      <div className={wrap}>
        <div className={clsx(section__header, wrap)}>
          <div className={clsx(container, flexColumn)}>
            <div>
              <p className={white}>쯩 한번으로 쉽고 간편하게 나를 증명하세요.</p>
              <Title
                text={"인증이 필요할 때, 쯩"}
                color="white"
                style={{
                  fontSize: "5.0rem",
                  lineHeight: "6.0rem",
                  fontWeight: 500,
                  marginBottom: 28,
                }}
              />
            </div>
            <div>
              <ButtonList
                buttonList={[
                  <Button
                    text="Google Play"
                    onClick={() => {
                      window.open(
                        "https://play.google.com/store/apps/details?id=com.iconloop.zzeung"
                      );
                    }}
                    buttonType={ButtonType.googlePlayWhite}
                  />,
                  <Button
                    text="App Store"
                    onClick={() => {
                      window.open("https://itunes.apple.com/app/id1512732128");
                    }}
                    buttonType={ButtonType.appleWhite}
                  />,
                ]}
              />
            </div>
          </div>
        </div>
        <div className={clsx(section__visual, wrap)}>
        </div>
        <div className={clsx(section__message, wrap)}>
          <div className={clsx(flex)}>
            <p className={quote}>
              일상에서 인증이 필요한 순간들을 마주칠 때,
              <br />
              이제 “쯩” 하나만 준비하세요
            </p>
          </div>
        </div>
        <div className={clsx(section__content1, wrap)}>
          <div className={clsx(container, flex)}>
            <div>
              <div className={phone}>
                <img
                    src={section__content1__png}
                    alt="비대면 계좌 개설할 때, 쯩 한 번으로 실명인증"
                    style={{
                      width: "356px",
                      maxWidth: "356px",
                      height: "452px",
                      position: "absolute",
                      left: 0,
                    }}
                />
              </div>
            </div>
            <div className={clsx(flexColumn)}>
              <Title
                text={`비대면 계좌 개설할 때,\n쯩 한 번으로 실명인증`}
                color="black"
                style={{
                  marginBottom: 60,
                }}
              />
              <Tab
                menu={["실명인증", "혁신금융서비스"]}
                components={[
                  <div className={tabItem}>
                    <p>
                      매번 신분증 촬영하고, 1원 송금할 필요 없이
                      <br />
                      금융 업무에 실명인증이 필요할 때 지문 한 번에 OK!
                    </p>
                  </div>,
                  <div className={tabItem}>
                    <p>
                      쯩은 블록체인 신원 증명(DID)을 활용한
                      <br />
                      금융 위원회 지정 혁신 금융 서비스(MyID)입니다.
                      <br />
                      <img
                        src={section__content1__myid}
                        width="198"
                        alt="금융위원회 X MyID"
                      />
                    </p>
                  </div>,
                ]}
                tabType="small"
              />
            </div>
          </div>
        </div>
        <div className={clsx(section__content2, wrap)}>
          <div className={clsx(container, flex)}>
            <div className={clsx(flexColumn)}>
              <Title
                text={`렌터카 예약할 때,\n쯩으로 운전면허인증`}
                color="black"
                style={{
                  marginBottom: 60,
                }}
              />
              <Tab
                menu={["운전면허인증", "운전자 사전등록"]}
                components={[
                  <div className={tabItem}>
                    <p>
                      운전면허증 촬영 한 번으로 바로 발급!
                    </p>
                  </div>,
                  <div className={tabItem}>
                    <p>
                      운전면허인증으로 운전자 사전등록하고
                      <br />
                      간편하게 렌터카 이용하세요.
                      <br />
                    </p>
                  </div>,
                ]}
                tabType="small"
              />
            </div>
            <div>
              <div className={phone}>
                <img
                    src={getGifUrl(section__content2__driver__gif)}
                    alt="렌터카 예약할 때,
                  쯩으로 운전면허인증"
                    style={{
                      width: "276px",
                      height: "582px"
                    }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className={clsx(section__content3, wrap)}>
          <div className={clsx(container, flex)}>
            <div>
              <div className={phone}>
                <img
                  src={getGifUrl(section__content3__gif)}
                  alt="인증이 필요한 순간"
                />
              </div>
            </div>
            <div className={clsx(flexColumn)}>
              <Title
                text={`증명서 관리가 필요할 때,\n쯩으로 한 번에`}
                color="black"
                style={{
                  marginBottom: 60,
                }}
              />
              <Tab
                menu={["증명서 관리", "쯩 X broof "]}
                components={[
                  <div className={tabItem}>
                    <p>
                      필요할 때 찾기 어렵고 새로 발급받아야 하는 증명서, <br />
                      이제 쯩에 등록하고 한곳에서 관리하세요.
                    </p>
                  </div>,
                  <div className={tabItem}>
                    <p>
                      디지털 증명서 관리 서비스 broof 와 함께합니다.
                      <br />
                      broof에서 발급한 증명서를 쯩에서도 관리할 수 있어요!
                    </p>
                  </div>,
                ]}
                tabType="small"
              />
            </div>
          </div>
        </div>
        <div className={clsx(section__content4, wrap)}>
          <div className={clsx(container, flex)}>
            <div className={clsx(flexColumn)}>
              <Title
                text={`안심하고\n믿을 수 있어요`}
                color="black"
                style={{
                  marginBottom: 30,
                }}
              />
              <p>
                쯩은 블록체인 DID(신원증명) 기술을 활용한
                <br />
                신원인증 서비스입니다.
                <br />
                위변조가 불가하고 모든 정보는 암호화되어 안전하게
                <br />내 휴대폰에 보관됩니다.
              </p>
            </div>
            <div>
              <div>
                <img
                  src={getGifUrl(section__content4__gif)}
                  alt="안심하고 믿을 수 있어요"
                />
              </div>
            </div>
          </div>
        </div>
        <div className={clsx(section__contact, wrap)}>
          <div className={clsx(container, flexColumn)}>
            <Title
              text={`서비스 도입이 필요하시면 문의해 주세요`}
              color="white"
            />
            <ButtonList
              style={{ marginTop: 34 }}
              buttonList={[
                <Button
                  text="서비스 소개서 다운로드"
                  onClick={() => {
                    const link = document.createElement("a");
                    link.href = section__contact__pdf;
                    link.target = "_blank";
                    link.download = "myid_brochure_v1.0.pdf";
                    link.dispatchEvent(new MouseEvent("click"));
                  }}
                  buttonType={ButtonType.white}
                  style={{
                    padding: "14px 14px",
                    letterSpacing: "-0.02rem",
                  }}
                />,
                <Button
                  text="서비스 도입문의"
                  onClick={() => {
                    const link = document.createElement("a");
                    link.href = "mailto:zzeungbiz@iconloop.com";
                    link.target = "_blank";
                    link.dispatchEvent(new MouseEvent("click"));
                  }}
                  buttonType={ButtonType.white}
                  style={{
                    padding: "14px 14px",
                    letterSpacing: "-0.02rem",
                  }}
                />,
              ]}
            />
          </div>
        </div>
        <div id="partners" className={clsx(section__partners, wrap)}>
          <div className={clsx(container, flex)}>
            <div>
              <Title text="쯩과 함께 하고 있어요" color="black" />
              <p>
                쯩은 아이콘루프가 만든 DID 플랫폼 ‘MyID’ 기반의 디지털 신원인증
                서비스입니다.
                <br />
                많은 파트너사들이 MyID 에서 새로운 디지털 ID 생태계를 함께
                만들고 있습니다.
              </p>
            </div>
            <div>
              <Button
                text="파트너사 신청"
                buttonType={ButtonType.black}
                onClick={() => window.open("https://myidalliance.org/")}
              />
            </div>
          </div>
          <ul className={clsx(container)}>
            {_partnersData.map((logo: string, i: number) => (
              <li
                style={{
                  backgroundImage: `url(${logo})`,
                }}
                key={i}
              ></li>
            ))}
          </ul>
          <div
            className={clsx("toggle", isPartnersShown ? "close" : "open")}
            onClick={() => togglePartners(!isPartnersShown)}
          >
            {isPartnersShown ? "접기" : "더보기"}
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default MainPage;
