import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Route, Switch, useLocation } from "react-router-dom";
import { MainPage, ServiceCenterPage, VaccineGuidePage } from "./pages";
import { ROUTES } from "./constants";
import { createUseStyles } from "react-jss";
import { ThemeType } from "./styles/theme";

const useStyles = createUseStyles((theme: ThemeType) => ({
  "@global": {
    html: {
      fontSize: "62.5%",
      fontFamily: theme.font,
      letterSpacing: "-0.02rem",
      wordBreak: "keep-all",
      wordWrap: "break-word",
      color: theme.mono0,
      fontWeight: theme.regular,
      lineHeight: "2.8rem",
      background: theme.mono100,
      overflowX: "hidden",
      [theme["breakpoint-md"]]: {
        overflowX: "auto",
      },
    },
    body: {
      fontSize: "1.6rem",
      "-webkit-font-smoothing": "antialiased",
      "-moz-osx-font-smoothing": "grayscale",
      lineHeight: "2.8rem",
      background: theme.mono100,
      overflowX: "hidden",
      [theme["breakpoint-md"]]: {
        overflowX: "auto",
      },
    },
    a: {
      fontSize: "1.4rem",
      color: theme.primaryTxt,
      textDecoration: "none",
      outline: "none",
      "&:hover": {
        textDecoration: "none",
        color: theme.primaryTxt,
      },
      "&:active": {
        textDecoration: "none",
        color: theme.primaryTxt,
      },
    },
  },
}));

function Routes() {
  useStyles();

  const location = useLocation<any>();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  return (
    <>
      <Helmet>
        <title>쯩 - 인증이 필요할 때, 쯩</title>
      </Helmet>
      <Switch>
        <Route exact path={ROUTES.home} component={MainPage} />
        <Route path={ROUTES.serviceCenter} component={ServiceCenterPage} />
        <Route path={ROUTES.vaccineGuide} component={VaccineGuidePage} />
      </Switch>
    </>
  );
}

export default Routes;
