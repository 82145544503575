import React from "react";
import { createUseStyles } from "react-jss";
import { ThemeType } from "../styles/theme";
import { CSSProperties } from "react";

import googleBlack from "../assets/icon-googleplay-color@2x.png";
import appleBlack from "../assets/icon-apple-black@2x.png";
import google from "../assets/icon-googleplay@2x.png";
import apple from "../assets/icon-apple@2x.png";
import videoPlay from "../assets/icon-play@2x.png";
import rightArrowWhite from "../assets/right-arrow-white@2x.png";
import rightArrowBlack from "../assets/right-arrow-black@2x.png";
import clsx from "clsx";

export enum ButtonType {
  googlePlayBlack,
  googlePlayWhite,
  appleBlack,
  appleWhite,
  videoPlay,
  white,
  black,
  black__plain
}

interface Props {
  text: string;
  buttonType: ButtonType;
  onClick: (e: React.MouseEvent<HTMLElement>) => void;
  style?: CSSProperties;
}

const useStyles = createUseStyles((theme: ThemeType) => ({
  default: {
    background: theme.mono0,
    width: 164,
    textAlign: "center",
    fontSize: "1.6rem",
    fontWeight: theme.demiLight,
    padding: "14px 19px",
    border: "0",
    borderRadius: "6px",
    cursor: "pointer",
    color: theme.mono100,
    display: "flex",
    alignItems: "center",
    letterSpacing: "0.03rem",
    lineHeight: "1.75rem",
    height: 56,
    justifyContent: "center",
  },
  white: {
    background: theme.mono100,
    color: theme.mono0,
  },
  ghost: {
    background: "none",
    color: theme.mono100,
    border: `1px solid rgba(255,255,255,0.6)`,
    borderRadius: 6,
  },
  rightIcon: {
    "& > div": {
      flexDirection: "row-reverse",
      "& > img": {
        marginRight: 0,
        marginLeft: 6,
      },
    },
  },
  innerWrap: {
    display: "flex",
    alignItems: "inherit",
  },

  icon: {
    marginRight: 6,
  },
}));

const Button: React.SFC<Props> = (props) => {
  const classes = useStyles(props);
  const { text, buttonType, onClick, style = {} } = props;

  const getClasses = () => {
    switch (buttonType) {
      case ButtonType.googlePlayBlack:
      case ButtonType.appleBlack:
        return clsx(classes.default);
      case ButtonType.appleWhite:
      case ButtonType.googlePlayWhite:
        return clsx(classes.default, classes.ghost);
      case ButtonType.videoPlay:
        return clsx(classes.default, classes.rightIcon);
      case ButtonType.white:
        return clsx(classes.default, classes.white, classes.rightIcon);
      case ButtonType.black__plain:
        return clsx(classes.default)
      case ButtonType.black:
      default:
        return clsx(classes.default, classes.rightIcon);
    }
  };

  const getIcon = () => {
    switch (buttonType) {
      case ButtonType.googlePlayBlack:
        return googleBlack;
      case ButtonType.googlePlayWhite:
        return google;
      case ButtonType.appleBlack:
        return appleBlack;
      case ButtonType.appleWhite:
        return apple;
      case ButtonType.videoPlay:
        return videoPlay;
      case ButtonType.white:
        return rightArrowBlack;
      case ButtonType.black:
      default:
        return rightArrowWhite;
    }
  };

  const getWidth = () => {
    switch (buttonType) {
      case ButtonType.googlePlayBlack:
      case ButtonType.appleBlack:
        return 160;
      case ButtonType.googlePlayWhite:
      case ButtonType.appleWhite:
        return 164;
      case ButtonType.videoPlay:
        return 124;
      case ButtonType.white:
        return 220;
      case ButtonType.black:
      default:
        return 170;
    }
  };

  const getIconWidth = () => {
    switch (buttonType) {
      case ButtonType.googlePlayBlack:
      case ButtonType.appleBlack:
        return 28;
      case ButtonType.googlePlayWhite:
      case ButtonType.appleWhite:
        return 20;
      case ButtonType.videoPlay:
      case ButtonType.white:
      case ButtonType.black:
      default:
        return 16;
    }
  };

  const getHeight = () => {
    switch (buttonType) {
      case ButtonType.videoPlay:
        return 50;
      default:
        return 56;
    }
  };

  return (
    <div
      onClick={onClick}
      className={getClasses()}
      style={{
        ...style,
        width: getWidth(),
        height: getHeight(),
      }}
    >
      <div className={classes.innerWrap}>
        {
          buttonType === ButtonType.black__plain ?
            (null) : (
              <img
                className={classes.icon}
                width={getIconWidth()}
                src={getIcon()}
                alt={text}
              />
            )
        }
        <p>{text}</p>
      </div>
    </div>
  );
};

export default Button;
