import { createUseStyles } from "react-jss";
import { ThemeType } from "../styles/theme";

const useCommonStyles = createUseStyles((theme: ThemeType) => ({
  wrap: {
    width: "100%",
  },
  container: {
    width: 940,
    marginLeft: "auto!important",
    marginRight: "auto!important",
  },
  flex: {
    display: "flex",
  },
  flexColumn: {
    display: "flex",
    flexDirection: "column",
  },
  quote: {
    fontSize: "3.0rem",
    lineHeight: "4.6rem",
    textAlign: "center",
  },
  textWrap: {
    justifyContent: "center",
    "& p": {
      fontWeight: theme.demiLight,
      marginBottom: 16,
      letterSpacing: "-0.02rem",
      "&:last-of-type": {
        marginBottom: 0,
      },
    },
  },
  phone: {
    boxShadow: "0 6px 26px 0 rgba(0, 0, 0, 0.14)",
    borderRadius: 30,
    position: "absolute",
    height: "auto",
  },
  white: {
    color: theme.mono100,
  },
  gray: {
    color: theme.mono50,
  },
  bold: {
    fontWeight: theme.medium,
  },
}));

export { useCommonStyles };
